import React, {useState, useEffect} from "react";
import axios from "axios";

import MaterialTable from '@material-table/core';
import Add from '@material-ui/icons/Add';
import Delete from '@material-ui/icons/Delete';
//import Subtract from '@material-ui/icons/Remove';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import AddCustomerForm from "../../AddCustomerForm/AddCustomerForm"
import DeleteForm from "../../DeleteForm/DeleteForm"

import "./CustomersPage.css";

//import { set } from "lodash";

function CustomersPage(props){
  const organizationId= props.id;

  const [list, setList] = useState({
    customers:[]
  })

  const [customerEvent, setCustomerEvent] = useState("");

  useEffect(() => {
    let mounted = true;
    function getProduct () {
      axios.get("/customerList/" + organizationId, {})
          .then((response) => {
            const data = response.data;
            
            if (mounted){
              setList({customers:data.foundItems});
              //setList({warehouses: data});
              //console.log("invitemList data received: ", data);
              //console.log("list is ", list.warehouses);
            }
          })
          .catch(() => {
            alert("Error Retrieving data!");
          });
        }
        getProduct();
        return () =>{
          mounted = false;
        };
      }, []);

      const columns = [
        {
          title: "Account Name",
          field: "accountName",
          render: row => (<div> {row.accountName} </div>)
          //render: row => (<div> <a href={"/customers/"+row._id}> {row.accountName} </a> </div>)
        }
        /*,
        {
          title: "Inventory2",
          field: "inventory[0].invTotal",
          customFilterAndSearch: "",
        }*/
      ]

      const handleClose = () => {
        setCustomerEvent({
          bool:false,
          event: ""});
      };

    return(
    <div>
        <>
        {console.log(list.customers)}
        {customerEvent ? 
        <>
        <Dialog open={customerEvent.bool} onClose={handleClose}>
          <DialogContent>
            { 
            customerEvent.event ==="new" ?
              <AddCustomerForm
              organizationId={organizationId}
              customerEvent={customerEvent.event}/> : 
              null
            }
            { 
            customerEvent.event ==="add" ?
              <AddCustomerForm
              organizationId={organizationId}
              customerEvent={customerEvent.event}
              customer={customerEvent}
              add={true}/> : 
              null
            }
            { customerEvent.event ==="delete" ?
            <DeleteForm
              objectName ={customerEvent.customerName}
              objectId={customerEvent.customerId}
              delURL={"/customerDelete"}
              delMessage = {"Deleting customer accounts will delete ALL invoices associated as well. To proceed"}
            /> 
            : null 
            }


          </DialogContent>
        </Dialog>

        </> : 
        null }
      </>

      <h2 className="pageTitle">Customers</h2>

    <br/>
     
    <MaterialTable
          className="Waretable"
          title="Customer Accounts"
          data= {list.customers}
          columns={columns}
          /*
          editable={{
            onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                const dataUpdate = [...data];
                const index = oldData.tableData.id;
                dataUpdate[index] = newData;
                setData([...dataUpdate]);
  
                resolve();
              }, 1000)
            })
          }}*/
          actions={[
            {
              icon: Add,
              tooltip: 'New Customer',
              isFreeAction: true,
              onClick: (event) => { 
                setCustomerEvent({
                  bool: true,
                  event: "new"
                });
                //console.log(customerEvent);
              }
            },
            {
              icon: Add,
              tooltip: 'Add Location',
              onClick: (event, rowData) => {
                setCustomerEvent({
                  bool: true,
                  event: "add",
                  customerId: rowData._id,
                });
              }
            },   
            {
              icon: Delete,
              tooltip: 'Delete Account',
              onClick: (event, rowData) => {
                setCustomerEvent({
                  bool: true,
                  event: "delete",
                  customerName: rowData.accountName,
                  customerId: rowData._id,
                });
              }
            }
          ]}
          options={{
            search: true , 
            titleStyle:{
              fontWeight: 'bold',
              fontSize: '4rem'
            },
            headerStyle:{backgroundColor:'',
              fontWeight: 'bold',
              fontSize: '2rem'}, 
            rowStyle: {
                backgroundColor: '#EEE',
                fontSize:'2rem'
              },
            title: {backgroundColor:'#EEE'}, 
            paging: false, 
            filtering: false, 
            exportButton: true, 
            actionsColumnIndex: -1}}
          detailPanel={[
            {
              tooltip: 'Show Details',
              render: rowData => {
                return (
                  <div
                    style={{  
                      backgroundColor: '#EEE',
                      fontSize:'2rem',
                      padding: "2rem"
                    }}
                  >
                    <div> 

                    {rowData.rowData.locations.map((location, index) => {
                      return(
                        <div key={index}>
                        <>
                        Location {index+1}: {location.locationName}
                        {console.log("locations map ", index)}
                        </>
                        </div>
                      )
                    })}

                    </div>
                    </div>
                )
              },
            },
          ]}
    />
    </div>
    );
}

export default CustomersPage;