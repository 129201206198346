import React, {useState, useEffect} from "react";
import axios from "axios";
import ListItem from "../../ListItem/ListItem";


import SingleLineForm from "../../SingleLineForm/SingleLineForm";

import "./WarehousesPage.css";

//import { set } from "lodash";

function WarehousesPage(props){
const organizationId= props.id;
  const [list, setList] = useState({
    warehouses:[]
  })

  useEffect(() => {
    let mounted = true;
    function getProduct () {
      axios.get("/warehouseList/" + organizationId, {})
          .then((response) => {
            const data = response.data;
            if (mounted){
              setList({warehouses: data});
              //console.log("invitemList data received: ", data);
              //console.log("list is ", list.warehouses);
            }
          })
          .catch(() => {
            alert("Error Retrieving data!");
          });
        }
        getProduct();
        return () =>{
          mounted = false;
        };
      }, [organizationId]);

    return(
    <div>
      <h2 className="pageTitle">Warehouses</h2>

 <br/>
     
 {list.warehouses !== undefined ? 
   list.warehouses.map((listItem, index)=>{
        return (
          <ListItem 
            key={index}
            id={index}
            name={listItem.warehouseName}
            warehouse={listItem._id}
            url= {"/warehouses/" + listItem._id}
            delurl={"warehouse/" + listItem._id}
            organizationId={organizationId}
            lot={listItem.lotTracking}
          />
        );
        }) : null
    
  }
      <SingleLineForm
        placeholder={"New Warehouse"}
        url={"/newWarehouse"}
        name={"newWarehouse"}
        organizationId={organizationId}
        checkboxcaption={"Lot Tracking?"}
        checkBoxValue={"lotTracking"}
        >
      </SingleLineForm>
    
    </div>
    );
}

export default WarehousesPage;