import React from "react";

function reportGenerate(props) {
  let report = props.report || {};
  let reportState = props.reportState;
  console.log("Generate report has: ", report, " ", reportState);
  return (
    <>
      {reportState.report === "history" ? (
        <>
          {" "}
          <div>Date History Report: </div>
          <br />
          <table>
            <tbody>
              <tr>
                <th>Event</th>
                <th>Quantity </th>
              </tr>
              {Object.entries(report).map(([eventType, sum], index) => (
                <tr key={index}>
                  <td>{eventType}</td>
                  <td>{sum}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : null}
      {reportState.report === "dateHistory" ? (
        <>
          <div>Date History Report: </div>
          <br />
          <table>
            <thead>
              <tr>
                <th>Item</th>
                <th>Event</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(report).map(([itemName, events], index) => (
                <React.Fragment key={index}>
                  {Object.entries(events).map(([eventType, qty], subIndex) => (
                    <tr key={`${index}-${subIndex}`}>
                      {/* Show the item name only on the first row for each item */}
                      <td>{subIndex === 0 ? itemName : ""}</td>
                      <td>{eventType}</td>
                      <td>{qty}</td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </>
      ) : null}
      {reportState.report === "whoBoughtWhat" ? (
        <>
          Who bought what <div></div>
          <br />
          <table>
            <tbody>
              <tr>
                <th>Order For Date</th>
                <th>Customer</th>
                <th>Invoice</th>
                <th>Product</th>
                <th>Total Qty</th>
                <th>Break Down Lot</th>
              </tr>

              {report.map((reportLine, index) => {
                //console.log("WHYYY ", reportLine);
                return (
                  <>
                    <tr key={index}>
                      <td>{reportLine.dateFor.substring(0, 10)}</td>
                      <td>{reportLine.customerName}</td>
                      <td>{reportLine.invoiceNum}</td>
                      <td>{reportLine.productName}</td>
                      <td>{reportLine.quantity}</td>
                      <td>
                        {reportLine.commitedLots.map((lot, index1) => {
                          return (
                            <>
                              <p>{"Lot: " + lot.lot + " Qty: " + lot.qty}</p>
                            </>
                          );
                        })}
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </>
      ) : null}
    </>
  );
}

export default reportGenerate;
