import React, {useState, useEffect} from "react";
import axios from "axios";
import "./LoginForm.css";

import { BrowserRouter as Switch, Redirect } from "react-router-dom"

function LoginForm(props) {

  const type = props.isRegistered ? "signin" : "signup";
  //console.log(type)
  const [user, setUser] = useState({
    email: "",
    password: "",
    passwordConfirm:"",
    errorMessage: "",
    organizations: ({
      id: "",
      name: ""
    }),
    isAuthenticated: ""
  });
  const [toDash, setToDash] = useState(false);

  const [promptText, setPrompt] = useState({
    valid: false,
    text:"default"
  });

  useEffect(() => {
    //console.log("Used Effect");
  
  }, []);

  function handleChange(event) {
    const { name, value } = event.target;
    setUser(prevValue => ({ ...prevValue, [name]: value } ));  
    //console.log(name);
    //console.log(value);
  }

function submit(event) {
  event.preventDefault();
  if (type === "signup"){
    if (user.password.length < 6){
      setPrompt({text: "Password must be at least 6 characters."});
    } else if(user.password !== user.passwordConfirm){ 
      setPrompt({text: "Password and Confirm Password must match."});
      console.log("inside signup and passwords dont match", user.password, user.passwordConfirm, "length is ", user.password.length);
    } else {
      console.log("we inside set to true");
      setPrompt({valid: true});
      axiosSubmit();
    }
  }

  if (type === "signin"){
    axiosSubmit();
  }
};

function axiosSubmit(){
    const payload = {
      email: user.email,
      password: user.password
    };
    axios({
      url: "/" + type,
      method: "POST",
      data: payload
    })
    .then((response) => {
      console.log("data has been sent ", response.data);
      const isAuthenticated = response.data.user.isAuthenticated;
      const userId = response.data.user._id
      const organization = response.data.user.organizations.id;
      window.localStorage.setItem("isAuthenticated", isAuthenticated);
      window.localStorage.setItem("organization", organization);
      window.localStorage.setItem("userId", userId);
      setToDash(true);
      //RELOAD PAGE TO BRING HOME
      window.location.reload(true);
    })
    .catch((error) => {
      console.log("error is", error);
      setUser(prevValue => ({ ...prevValue, errorMessage: error.response.data.message  } )); 
    //GET THIS WORKING IN STATE SO CAN APPEAR ON COMPONENT AND LOOK PROFESSIONAL
    // console.log("server error: ", error.response.data.message);
    });
}

  return (<>
  { toDash ? <> <Switch> 
    <Redirect to="/" /> </Switch>  </>: null}

    <form action="POST" className="form register logincust">
      <input type="text" name="email" value={user.email} placeholder="email" onChange= {handleChange} />
      <input type="password" name="password" value={user.password} placeholder="Password" onChange= {handleChange}/>
      {!props.isRegistered && (
        <input type="password" name="passwordConfirm" value={user.passwordConfirm} placeholder="Confirm Password" onChange= {handleChange}/>
      )}

      <button type="submit" url="/" onClick={submit}>{props.isRegistered ? "Login" : "Register"}</button>
    </form>

    <div className="prompt">{promptText.text}</div>
      
    <br></br>
    <br></br>
    </>
  )
}

export default LoginForm;
