import React, {useState, useEffect} from "react";
import axios from "axios";
import "../LoginForm/LoginForm.css";
//import {Redirect} from "react-router-dom";
//import {useHistory} from "react-router";

function NewUserForm(props) {
  //const history = useHistory();
  console.log(props)
  const [organization, setBakery] = useState({
    id: "",
    name: "",
    warehouse:"",
    errorMessage: "",
    organizations: ({
      id: "",
      name: ""
    }),
    isAuthenticated: ""
  });
  const [firstQuestion, setFirst] = useState(true);
  const [secondFormDialogue, setSecondForm] =useState({
    firstLine:"",
    secondLine:""
  });
  const [userCreating, setStatus] = useState("");

  useEffect(() => {
    console.log("Used Effect");
  
  }, []);

  function handleChange(event) {
    const { name, value } = event.target;
    setBakery(prevValue => ({ ...prevValue, [name]: value } ));  
    console.log(name);
    console.log(value);
  }

function submit(event) {
  //event.preventDefault();
  
  window.localStorage.removeItem("bakery");
  

  //const { name, value } = event.target;
  const random = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  console.log(userCreating);
  const payload = {
    create: userCreating,
    name: organization.name,
    warehouse: organization.warehouse,
    random: random
  };
  axios({
    url: "/newOrganization",
    method: "POST",
    data: payload
  })
  .then((response) => {
    console.log("data has been sent ", response.data);
    const organization = response.data.organizationId;
    console.log("The bakery key should set to ", organization);
    window.localStorage.setItem("organization", organization);
    //history.push("/");
    window.location.reload(true);
  })
  .catch((error) => {

  });
};

function createOrJoin(event){
  event.preventDefault();
  setFirst(false);
  const createOrJoin = event.target.value;
  setStatus(createOrJoin);
  if (createOrJoin === "create"){
    setSecondForm(["We are happy to see you joining EasyIn.", 
    "We believe in simplicity. Just name your warehouse and organization and you can get started!"]);
  } else if (createOrJoin ==="join"){
    setSecondForm(["Welcome aboard!.", 
    "Just enter a provided join code to join your team."]);
  }
  
  console.log(userCreating);
}
  return (<>
    { firstQuestion ? 
    <>
    <h3 className="pageTitle">Welcome! Looks like you're new here.</h3>
    <h3 className="pageTitle">Are you the first member joining us from your organization?</h3>
    <form> 
    <button type="submit" value="create" onClick={createOrJoin}>Yes, I want to setup my Organization</button>
    <button type="submit" value="join" onClick={createOrJoin}>No, I need to join my Organization </button>
    </form> </>: <>
        <h3 className="pageTitle">{secondFormDialogue[0]}</h3>
        <h3 className="pageTitle">{secondFormDialogue[1]}</h3>
        <form action="POST" className="form register logincust">
        {userCreating === "create" ? <input type="text" name="warehouse" value={organization.warehouse} placeholder="Warehouse" onChange= {handleChange}  /> : null}
        
        <input type="text" name="name" value={organization.name} placeholder={userCreating === "create" ? "Name of your organization." : "CK Join Code"} onChange= {handleChange} />
        <button type="submit" onClick={submit}>{userCreating === "create" ? "Start storing!" : "Join team."}</button>
      </form>
</>
     }
    
    </>

  )
}

export default NewUserForm;
