import React, { useState, useEffect } from "react";

import axios from "axios";
import "./PostedUserFormPage.css";
import _ from "lodash";

import UserForm from "../../UserForm/UserForm";

function PostedUserFormPage(props) {
  let organizationId = props.id;
  let historyId = props.historyId;
  let bakeryName = props.name;

  const [editState, setEditState] = useState("locked");

  const [form, setForm] = useState({
    id: "",
    formName: "",
    fields: [],
    index: "",
    fieldResponses: [],
  });

  const [recordedData, setRecordedData] = useState({
    fields: [],
    index: "",
    fieldResponses: [{
      column: [{
        rows: [{
          value: ""
        }]
      }]
    }],
  });

  const [eventTimeLog, setTimeLog] = useState({
    time: "",
    date: ""
  })
  //console.log(bakeryName)
  //console.log("props ", props);
  const [headingText, setHeading] = useState("");

  useEffect(() => {
    setHeading(bakeryName + "'s");
    function getPostedForm() {
      axios.get("/getPostedForm/" + historyId, {
      })
        .then((response) => {
          console.log("RESPONSE: ", response.data);
          let userForm = response.data.userForm;

          /*
          var result = response.data.userForm.history.find(obj => {
            return obj._id === historyId;
          })
*/

          for (var i = 0; userForm.history.length > i; i++) {
            if (userForm.history[i]._id === historyId) {
              setRecordedData(userForm.history[i]);
            }
          }

          setForm(userForm);
          //setRecordedData({fieldResponses: result.fieldResponses});
          //WILL GET BAKERY DATA WHEN THERE IS DATA 
          //console.log(response.data);
          console.log("data has been received: ");
        })
        .catch(() => {
          alert("Error Retrieving data!");
        });
    }

    getPostedForm();
    //console.log("Used Effect");
  }, [organizationId, historyId]);

  function setEdit(event) {
    event.preventDefault();
    if (editState === "locked") {
      setEditState("editable");
    }
    if (editState === "editable"){
      setEditState("locked");
      submit();
    }
    console.log("inside set edit");
  }


  
  function handleChange(event) {
    //event.preventDefault();
    if (editState === "editable") {
      const { name, value } = event.target;
      console.log("name: ", name, " value ", value);

      const now = new Date().toLocaleTimeString();
      //const date = new Date().toLocaleDateString();
      const date = new Date().toUTCString();


      if (name.includes("tableResponse")) {
        let parameters = name.substring(14);
        let [index, column, row] = parameters.split("~");
        let fieldResponses = recordedData.fieldResponses;
        fieldResponses[index].column[parseInt(column)].rows[row] = {
          value: value
        }
        //console.log("parameters: ", parameters, " index: ", index, " column: ", column, " row: ", row);

        setForm(prevPost => {
          return {
            ...prevPost,
            fieldResponses: fieldResponses
          };
        });

      }
      else {
        /*
        setForm(prevPost => {
          return {
            ...prevPost,
            [name]: value
          };
        });
        */
      }

      setTimeLog({
        time: now,
        date: date
      })
    }
  }

  function submit() {
    //event.preventDefault();
    //const { name, value } = event.target;
    const payload = {
      organizationId,
      form,
      recordedData,
      eventTimeLog
    };

    axios({
      url: "/updatePostedForm/" + form._id,
      method: "POST",
      data: payload
    })
      .then(() => {
        console.log("data as been snet0", payload);
        //setToDash(true);
        //console.log(toDash);
        window.location.reload(true);
        //getProduct();
      })
      .catch(() => {
        console.log("server error");
      });
  };

  return (
    <div>
      {console.log("recorded Data: ", recordedData.fieldResponses)}
      <h2 className="pageTitle"> POSTED USER FORM {form.formName} </h2>

      <form>

        <div id="" >
          <>
            {_.times(form.fields.length, i => (
              <>
                <div className="container scroll">

                  {form.fields[i].type === "table" ?
                    <>
                      <table className="fixed col-id-no">
                        <thead>
                          <tr>
                            <th> Row </th>
                          </tr>
                        </thead>
                        <tbody>
                          {_.times(form.fields[i].rows.length, x => (
                            <>
                              <tr >
                                <td >{form.fields[i].rows[x]}
                                </td>


                              </tr>
                            </>
                          ))}
                        </tbody>
                      </table>


                      <div className="">
                        <table className="scrollable">
                          <thead>
                            <tr>

                              {_.times(form.fields[i].columns.length, j => (
                                <th> {form.fields[i].columns[j]}</th>

                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {_.times(form.fields[i].rows.length, x => (
                              <>
                                <tr>

                                  {_.times(form.fields[i].columns.length, y => (
                                    <>{console.log("THE CRAZY STRING VALUE: ", recordedData.fieldResponses[i].column[y].rows[x].value)}
                                      <td>
                                        {recordedData.fieldResponses[i].column[y].rows[x].value !== undefined ? <>
                                          <input
                                            key={i + "~" + y + "~" + x}
                                            className="smaller-input"
                                            name={"tableResponse~" + i + "~" + y + "~" + x}
                                            onChange={handleChange}
                                            value={recordedData.fieldResponses[i].column[y].rows[x].value}
                                          >
                                          </input>
                                        </> : console.log("WERE IN THE FALSE CASE")}

                                      </td>
                                    </>
                                  ))}


                                </tr>
                              </>
                            ))}
                          </tbody>
                        </table>
                      </div>

                    </>
                    : null
                  }
                </div>
                <br /> </>
            ))}
          </>
        </div>


        <div className="" >
          <button onClick={setEdit}> {editState === "locked" ? <> Edit</> : <> Submit </>}</button>
        </div>


      </form>

    </div>
  );
}

export default PostedUserFormPage;