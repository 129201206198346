import React, { useState } from "react";
import axios from "axios";
import "./SingleLineForm.css";
import { Checkbox } from "@material-ui/core";


function SingleLineForm(props){
  console.log("Single line form props: ", props);
  const organizationId = props.organizationId

  const [formItem, setProduct] = useState({
    warehouse: props.props,
  });

  const checkBoxCaption = props.checkboxcaption;
  //const checkBoxValue = props.checkBoxValue;
  const [checkBoxInput, setCheckBox] = useState(false);

 function handleChange(event) {
  const { name, value } = event.target;
  setProduct(prevPost => {
    return {
      ...prevPost,
      [name]: value
    };
  });
}

function handleCheckBox(event) {
  event.preventDefault();
  const { name, value } = event.target;
  console.log("Name: ", name, " Value: ", value);
  if (checkBoxInput === false){
    setCheckBox(true);
  } else { 
    setCheckBox(false);  
  }
  console.log("CheckBox is", checkBoxInput)
}


function submit(event) {
  event.preventDefault();

  const now = new Date().toLocaleTimeString();
  //const date = new Date().toLocaleDateString();
  const date = new Date().toUTCString();

  //const { name, value } = event.target;
  const payload = {
    formItem: formItem,
    organizationId: organizationId,
    checkBoxInput: checkBoxInput,
    date: date,
    time: now
  };
  
  axios({
    url: props.url,
    method: "POST",
    data: payload
  })
  .then(() => {
    console.log("data as been snet0");
    window.location.reload(true);
    //getProduct();
  })
  .catch(() => {
    console.log("server error");
  });
};

    return (
      <>        
      <form className="" action={props.url} method="post">
      <div className="lineContainer">
      <input onChange={handleChange} className= "inputField" type="text" name={props.name} placeholder={props.placeholder} autoComplete="off"/>
      { checkBoxCaption === false ? null : <> {checkBoxCaption} <Checkbox value={checkBoxInput} checked={checkBoxInput} onChange={handleCheckBox}  onClick={handleCheckBox}></Checkbox> </>}

      </div>
      <button onClick={submit} value="">+</button>
    </form>
    </>
    );
    
  }

export default SingleLineForm;