import React, {useState, useEffect} from "react";
import axios from "axios";

import MaterialTable from '@material-table/core';

import Add from '@material-ui/icons/Add';
import Delete from '@material-ui/icons/Delete';
import Subtract from '@material-ui/icons/Remove';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import AddOrderForm from "../../AddOrderForm/AddOrderForm"
import DeleteForm from "../../DeleteForm/DeleteForm"

import "./CustomerPage.css";

//import { set } from "lodash";

function CustomerPage(props){
  console.log("WERE HERE ", props);
  const customerId = props.customerId;
  //const organizationId= props.id;

  const [customer, setCustomer] = useState({
  })

  const [addOrder, setAddOrder] = useState(false);

  //const [customerEvent, setCustomerEvent] = useState("");

  useEffect(() => {
    let mounted = true;
    function getProduct () {
      axios.get("/customerInfo/" + customerId, {})
          .then((response) => {
            const data = response.data;
            console.log("the data ", data);
            if (mounted){
              setCustomer({
                accountName: data.customer.accountName
              });
              console.log("customer set to: ", customer);
              //setList({customers:data.foundItems});
              //setList({warehouses: data});
              //console.log("invitemList data received: ", data);
              //console.log("list is ", list.warehouses);
            }
          })
          .catch(() => {
            alert("Error Retrieving data!");
          });
        }
        getProduct();
        return () =>{
          mounted = false;
        };
      }, []);

      const columns = [
        {
          title: "Account Name",
          field: "accountName",
          render: row => (<div> <a href={"/customer/",row._id}> {row.accountName} </a> </div>)
        }
        /*,
        {
          title: "Inventory2",
          field: "inventory[0].invTotal",
          customFilterAndSearch: "",
        }*/
      ]

      
      const handleClose = () => {
        setAddOrder(false);
      };

      function addOrderEvent(){
        console.log("inside event add order");
        setAddOrder(true);
      }

    return(
    <div>
      <>
        {addOrder?
        <Dialog open={addOrder} onClose={handleClose}>
          <DialogContent>
            <AddOrderForm
            customerId={customerId}>

            </AddOrderForm>
          </DialogContent>
        </Dialog>
        : null}

      <h2>{customer.accountName}</h2>
      <button onClick={addOrderEvent}>+ Order</button>
      {console.log("customer set to: ", customer)}
      </>
    </div>
    );
}

export default CustomerPage;