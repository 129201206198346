import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import "./AddOrderForm.css";

//import BarcodeScannerComponent from "react-webcam-barcode-scanner";

import SingleLineForm from "../SingleLineForm/SingleLineForm"

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import CropFreeIcon from '@material-ui/icons/CropFree';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import SearchIcon from '@material-ui/icons/Search';

import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

import CustomSelect from "../CustomSelect/CustomSelect"

//import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';

function AddOrderForm(props) {
  //ALL STATE VARIABLES DECALRED
  //console.log("THE PROPS " + props.event.dateFor);

  const [customerOptions, setCustomerOptions] = useState([{
    value: "",
    id: "",
    error: ""
  }]);

  const [productOptions, setProductOptions] = useState([{
    value: "",
    id: "",
    organizationId: ""
  }]);

  const [orderProps] = useState(props.event);

  const [customer, setCustomer] = useState({
    list: [],
    theCustomer: "",
    index: ""
  });

  const organizationId = props.organizationId;

  const [warehouseList, setWarehouseList] = useState({
    list: []
  });

  const [itemList, setItemList] = useState({
    list: []
  });

  const [orderInfo, setOrderInfo] = useState({
    orderId: "",
    pONum: "",
    warehouse: "",
    theCustomer: "",
    locationName: "",
    locationId: "",
    qtyAddValue: "",
    order: [],
    date: "",
    product: "",
    id: ""
  });

  const [eventTimeLog, setTimeLog] = useState({
    time: "",
    date: ""
  })

  const [data, setData] = useState('Not Found');
  const [scanner, setScanner] = useState(false);
  const [camera, setCamera] = useState(false);
  const [scannedValue, setScannerName] = useState("");


  //console.log("Event: " + orderProps);

  //GENERIC GLOBAL VARIABLES
  let customerIndex = 0;
  let orderIndex = 0;


  let currentOrder;
  let itemToAdd;

  useEffect(() => {
    let mounted = true;

    function getLocations() {
      axios.get("/" + orderProps.event + "/" + organizationId)
        .then((response) => {
          //const resdata = response.data;
          console.log("response data is ", response.data);
          if (mounted) {
            console.log("DEBUGGING: ", response.data.items);
            //Set Organizations Items To List


            //Set Organization Warehouses To List
            setWarehouseList({ list: response.data.foundWarehouses.warehouses })
            let itemsList = response.data.items;
            //setItemList({ list: response.data.items });
            console.log("ITEMLIST TO BE HANDLED: ", itemsList);
            handleSetItemList([itemsList]);
            let foundCustomers = response.data.foundCustomers;

            let customerOptions = foundCustomers.map(customer => {
              return {
                value: customer.accountName,
                id: customer._id
              }
            });

            //let customerOptions2 = foundCustomers.map(customer => customer.accountName);
            setCustomerOptions(customerOptions);

            //For Edit Order Case: Load in current info
            if (orderProps.event === "editOrder") {
              orderIndex = response.data.orders.findIndex(order => order._id === orderProps.orderId)
              currentOrder = response.data.orders[orderIndex].orderList;
              let dateFor = Date(response.data.orders[orderIndex].dateFor);
              console.log("EDIT ORDER CASE!!!! ");
              setOrderInfo(prevPost => {
                return {
                  ...prevPost,
                  warehouse: orderProps.warehouseId,
                  pONum: orderProps.PO,
                  date: dateFor,
                  theCustomer: orderProps.customerId,
                  locationId: orderProps.locationId,
                  order: currentOrder,
                  orderId: orderProps.orderId,
                  id: orderProps.orderId,
                  commitedLots: []
                }
              });
              customerIndex = response.data.foundCustomers.findIndex(customer => customer._id === orderProps.customerId);
              setCustomer(prevPost => {
                return {
                  ...prevPost,
                  list: foundCustomers,
                  index: customerIndex
                }
              });

              
              let filteredProductOptions = response.data.items.filter(item => item.warehouse[0] === orderProps.warehouseId)
              //FORMAT ITEM CHOICES FOR CUSTOM SELECT
              let productOptions = filteredProductOptions.map(item => {
                //console.log("Inside item map; ", item);
                return {
                  value: item.itemName,
                  id: item._id
                }
              })
              setProductOptions(productOptions);
              
              //handleSetProductList(orderProps.warehouseId);
              //console.log("PRODUCT OPTIONS: ", productOptions);
              

              let warehouseIndex = response.data.foundWarehouses.warehouses.findIndex(warehouse => warehouse._id === orderProps.warehouse);
            }
            else {  //not Edit Order case
              setCustomer(prevPost => {
                return {
                  ...prevPost,
                  list: foundCustomers
                }
              });
            }
          }
        })
        .catch(() => {
          alert("Error Retrieving data!");
        });
    }
    getLocations();
    //NEED TO GET THIS TO MAP TO ORDER
    return () => {
      mounted = false;
    };
  }, []);


  function handleSetItemList([itemArray]) {
    console.log("ITEMLIST BEING HANDLED: ", itemArray);
    setItemList({list: itemArray});
  }

  function handleSetProductList(warehouseID) {
    console.log("INSIDE HAND SET ITEM LIST Warehouse: ", warehouseID, " Item List: ", itemList);
    //FILTER ITEMS ONLY IN WAREHOUSE SELECTED
    let filteredProductOptions = itemList.list.filter(item => item.warehouse[0] === warehouseID)
    //FORMAT ITEM CHOICES FOR CUSTOM SELECT
    let productOptions = filteredProductOptions.map(item => {
      console.log("Inside item map; ", item);
      return {
        value: item.itemName,
        id: item._id
      }
    })
    //console.log("PRODUCT OPTIONS: ", productOptions);
    setProductOptions(productOptions);
  }


  const changeHandler = (value, name) => {
    console.log("value; ", value, " name; ", name, " event ");
    /*
    setCustomerData(prev => ({
      ...prev,
      [name]: {
        value,
        error: value !== '' ? '' : prev[name].error
      }
    }))
    */

    if (name === "theCustomer") {
      customerIndex = customer.list.findIndex(customer => customer._id === value.id);
      //console.log("CUSTOMER INDEX: ", customerIndex);
      //console.log("wtf ", customer.list[customerIndex].locations[0]);
      setCustomer(prevPost => {
        return {
          ...prevPost,
          index: customerIndex,
          locationId: customer.list[customerIndex].locations[0]._id
        }
      });

      setOrderInfo(prevPost => {
        return {
          ...prevPost,
          theCustomer: customer.list[customerIndex]._id,
          locationId: customer.list[customerIndex].locations[0]._id
        }
      });
    }
    if (name === "product") {

      setOrderInfo(prevPost => {
        return {
          ...prevPost,
          [name]: value.id
        };
      });
    }

  }


  function handleChange(event) {
    event.preventDefault();
    const { name, value } = event.target;
    //console.log("name: ", name, " value ", value);

    const now = new Date().toLocaleTimeString();
    //const date = new Date().toLocaleDateString();
    const date = new Date().toUTCString();
    //console.log("THE THING ", orderInfo.warehouse);
    if (name === "theCustomer") {
      setOrderInfo({
        [name]: value
      });
    }
    if (name === "warehouse") {
      //FILTER ITEMS ONLY IN WAREHOUSE SELECTED
      let filteredProductOptions = itemList.list.filter(item => item.warehouse[0] === value)
      //FORMAT ITEM CHOICES FOR CUSTOM SELECT
      let productOptions = filteredProductOptions.map(item => {
        //console.log("Inside item map; ", item);
        return {
          value: item.itemName,
          id: item._id
        }
      })
      setOrderInfo({
        orderId: "",
        warehouse: "",
        theCustomer: "",
        locationName: "",
        locationId: "",
        qtyAddValue: "",
        order: [],
        date: orderInfo.date,
        pONum: orderInfo.pONum,
        product: "",
        id: ""
      });
      //console.log("PRODUCT OPTIONS: ", productOptions);
      setProductOptions(productOptions);

    }

    if (name === "add") {
      itemToAdd = itemList.list.findIndex(item => item._id === orderInfo.product);
      currentOrder = orderInfo.order;
      currentOrder.push({
        productId: orderInfo.product,
        productName: itemList.list[itemToAdd].itemName,
        qty: orderInfo.qty
      });

      setOrderInfo(prevPost => {
        return {
          ...prevPost,
          "product": "",
          "qtyAddValue": "",
          "order": currentOrder
        };
      });
      //console.log(currentOrder);
    }

    if (name === "scannedValue") {
      setScannerName(value);
    }
    else {
      setOrderInfo(prevPost => {
        return {
          ...prevPost,
          [name]: value
        };
      });
    }
    setTimeLog({
      time: now,
      date: date
    })
  }

  function handleDelete(event) {
    event.preventDefault();
    const { name, value } = event.target;
    console.log("name: ", name, " value ", value);
    console.log("inside remove event. Value: ", value);
    if (value !== undefined) {
      currentOrder = orderInfo.order;
      currentOrder.splice(value, 1);
      setOrderInfo(prevPost => {
        return {
          ...prevPost,
          "product": "",
          "qtyAddValue": "",
          "order": currentOrder
        };
      });
    }
  }

  function onKeyPress(event) {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  }

  function handleClose() {
  }

  function handleCameraOpen(event) {
    const { name, value } = event.target;
    console.log("event is ", name, value);
    event.preventDefault();
    setScannerName(name);
    if (scanner === false) {
      setCamera(true);
    } else
      setCamera(false);
  }

  function handleBarcodeScan(input) {
    //event.preventDefault();
    setData("inside scan, ", input);
    console.log("input is; ", input);
    console.log("scannedValue ", scannedValue);
    setScanner(false);
  }

  function submit(event) {
    event.preventDefault();
    //const { name, value } = event.target;
    
    const payload = {
      organizationId,
      orderInfo,
      eventTimeLog
    };

    axios({
      url: "/" + orderProps.event + "/" + organizationId,
      method: "POST",
      data: payload
    })
      .then(() => {
        console.log("data as been sent", payload);
        //setToDash(true);
        //console.log(toDash);
        window.location.reload(true);
        //getProduct();
      })
      .catch(() => {
        console.log("server error");
      });
  };

  function openBarcodeScan() {
    if (scanner === false) {
      setScanner(true);
    } else
      setScanner(false);
    console.log("INSIDE RUN BARCODE LOOKUP");
  }

  function barcodeLookup() {
    console.log("INSIDE BARCODE LOOK UP. LIST OF ITEMS; ", itemList);
    const itemSelected = itemList.list.find(item => item.sku === scannedValue);
    if (itemSelected !== undefined) {
      //console.log("item is, ", itemSelected);)
      setOrderInfo(prevPost => {
        return {
          ...prevPost,
          "product": itemSelected._id,
        };
      });


      if (scanner === false) {
        setScanner(true);
      } else
        setScanner(false);

    } else {
      console.log("ITEM NOT FOUND!!!! ");
    }
  }


  return (
    /* This doesnt work from dashboard but everything seems fine without... 
    { toDash ? <> <Switch> 
      <Redirect to={("/warehouses/", invItem.warehouse)} /> </Switch>  </>: 
      null}
      */
    <>
      {//console.log("Customers: " + customer, " warehouses: " + warehouseList + " items: " + itemList.list)
      }
      {console.log("order info is: ", orderInfo)}
      <div className="addFormCustom">
        {scanner ?
          <> <h5>Barcode Lookup</h5>
            <input value={scannedValue} name="scannedValue" onChange={handleChange}></input>
            <SearchIcon fontSize="large" onClick={barcodeLookup} />

            {/*
            <BarcodeScannerComponent
              width={350}
              height={500}
              name={scannerName}
              value={scannerName}
              onUpdate={(err, result) => {
                if (result) {
                  handleBarcodeScan(result.text)
                }
                else setData('Not Found')
              }}
         
            />
   */}

            {//<PhotoCameraIcon fontSize="large" onClick={handleCameraOpen}/>
            }
            <p>{data}</p>
          </>
          : null}
        <>
          <Dialog open={false} onClose={handleClose}>
            <DialogContent>
              <SingleLineForm
                props={props}
                placeholder={"Location"}
                checkboxcaption={false}
                checkBoxValue={false}
                name="newLocation"
                url="/addLocation" />
            </DialogContent>
          </Dialog>
        </>

        <h2>ORDER FORM</h2>

        <form onKeyPress={onKeyPress}>

          <div id="" className="">
            <input
              type="text"
              className=""
              name="pONum"
              placeholder="PO # (Optional)"
              value={orderInfo.pONum}
              onChange={handleChange}
            />

            Order Date
            <input
              type="date"
              className=""
              name="date"
              placeholder="Date"
              value={orderInfo.date}
              onChange={handleChange}
            />

            <br />
            <h5>Warehouse</h5>
            <select
              className="selectField"
              type="text"
              name="warehouse"
              placeholder="warehouse"
              onChange={handleChange}
              value={orderInfo.warehouse}>

              <option value="">Warehouses</option>
              {warehouseList.list.map((listItem, index) => {
                return (
                  <option value={listItem._id} key={index}>{listItem.warehouseName}</option>
                )
              })}
            </select>
          </div>

          {orderInfo.warehouse !== "" ?
            <>
              {//<h5>Customer</h5>
              }

              <CustomSelect
                label="Select Customer"
                searchPlaceholder="Search for Customer"
                data={customerOptions}
                value={orderInfo.theCustomer.id}
                onChange={changeHandler}
                error={customerOptions.error}
                name="theCustomer"
              />


              {/*
              <select
                className="selectField"
                type="text"
                name="theCustomer"
                placeholder="Customer"
                onChange={handleChange}
                value={orderInfo.theCustomer}>

                <option value="">Customer</option>
                {customer.list.map((customer, index) => {
                  return (
                    <option value={customer._id} key={index}>{customer.accountName}</option>
                  )
                })}
              </select>
              */}
              {console.log("CHECKING CONDITION: ", orderInfo.theCustomer)}
              {orderInfo.theCustomer !== ""
                ?
                <>
                  {//console.log("customer list: ", customer.list)
                  }
                  {customer.list.length !== 0 && customer.list[customer.index].locations.length > 1
                    ?
                    <>
                      <h5>Deliver to </h5>
                      <select
                        className="selectField"
                        type="text"
                        name="locationId"
                        placeholder="Location"
                        onChange={handleChange}
                        value={orderInfo.locationId}>
                        {
                          customer.list[customer.index].locations.map((location, index) => {
                            //console.log("here ", location.locationName)
                            return (
                              <option value={location._id} key={index}>{location.locationName}</option>
                            )
                          })
                        }
                      </select>
                    </>
                    :
                    <>
                      {//handleChange(name: "location", value: customer.list[{customer.index}].locations[0] )
                      }
                    </>
                  }

                  <div className="rowItems">

                    <CustomSelect
                      label="Select Product"
                      searchPlaceholder="Search for Product"
                      data={productOptions}
                      //data={customerOptions.map(customer => customer.value)}
                      value={orderInfo.product}
                      onChange={changeHandler}
                      name="product"
                    />

                    {orderInfo.product === "" ?
                      <>
                        <CropFreeIcon fontSize="large" onClick={openBarcodeScan} />
                      </>

                      :
                      <>
                        <input
                          type="text"
                          className="qtyInputField"
                          name="qty"
                          placeholder="Quantity"
                          value={orderInfo.order[orderInfo.order.length]}
                          onChange={handleChange}
                        />

                        <button className="addProdButton" value="add" name="add" onClick={handleChange}> + </button>

                      </>
                    }
                  </div>

                  <div>

                    {orderInfo.order !== undefined && orderInfo.order.length >= 0 ?
                      <>
                        {//console.log("ther order: ", orderInfo.order)
                        }
                        {orderInfo.order.map((productOnOrder, index) => {
                          return (<div className="customRowContainer" key={index}>
                            <h4>{productOnOrder.productName} {productOnOrder.qty} </h4>

                            <button className="addButton" value={index} name={index} onClick={handleDelete}>X</button>
                          </div>

                          )
                        })}

                      </>
                      : null
                    }
                    {orderProps.event === "newOrder" ?
                      <>
                        {orderInfo.order.length > 0 ?
                          <button onClick={submit}> Submit Order </button> :
                          null
                        }
                      </> :
                      <button onClick={submit}> Update Order </button>
                    }
                  </div>
                </>
                :
                null
              }
            </>
            : null
          }
          <div className="customRowContainer" >
          </div>

          {false
            ? //lotTrack variable was here
            <div id="container">
              <input
                type="text"
                className="smallInputField"
                name="lot"
                placeholder="Associated Lot "
                value={"lot"}
                onChange={handleChange}
              />

              <button className="addProdPlusButton" value="lot" name="lot" onClick={handleCameraOpen}>+</button>
            </div>
            :
            null
          }


        </form>
      </div>
    </>
  );

}

export default AddOrderForm;