import React, { useRef, useEffect } from 'react';

//import "./Dropdown.scss";

const Dropdown = ({ searchPlaceholder, search, searchChangeHandler, options, selectedValue, changeSelectedHandler, name, selectedIndex, data }) => {
  const searchInputEl = useRef();
  const itemsEl = useRef();

  //console.log("DATA IS22; ", data);
  //console.log("OPTIONS IS; ", options);

  useEffect(() => {
    searchInputEl.current.focus();
    if(selectedValue) {
      itemsEl.current.scrollTop = itemsEl.current.querySelector(`.item-${selectedIndex}`).offsetTop - 42;
    }
    // eslint-disable-next-line
  }, []);

  return(
    <div className="dropdown__menu">
      <input 
        type="text" 
        placeholder={searchPlaceholder ? searchPlaceholder : 'Search...'}
        className="dropdown__menu_search"
        value={search}
        onChange={searchChangeHandler}
        ref={searchInputEl}
      />
      <div className="dropdown__menu_items" ref={itemsEl}>
        {options.map((item, index) => ( 
          <div
            className={selectedValue === item._id ? `dropdown__menu_item item-${data.indexOf(item)} selected` : `dropdown__menu_item item-${data.indexOf(item)}`}
            key={index}
            onClick={() => changeSelectedHandler(item, name, data.indexOf(item))}
            value={item.id}
          >
            {//console.log("Inside map", item) 
            }
            {item.value}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Dropdown;