import React, {useState, useEffect} from 'react';
import axios from "axios";
import DrawerToggleButton from "../SideDrawer/DrawerToggleButton";
import "./Toolbar.css";

import { BrowserRouter as Route, Router, Switch, Redirect } from "react-router"

function Toolbar(props){

const [user, setUser] = useState({
    email: "",
    password: "",
    errorMessage: "",
    isAuthenticated: ""
  });

const [toHome, setToHome] = useState(false);

  useEffect(() => {
    setUser(props);
  }, [props]);

function submit(event) {
    //event.preventDefault();
    //const { name, value } = event.target;
    const payload = {
    email: user.email,
    password: user.password
  };  
  window.localStorage.removeItem("isAuthenticated");
  window.localStorage.removeItem("organization");
    axios({
      url: "/signout",
      method: "GET",
      data: payload
    })
    .then((response) => {
      setToHome(true);
      //console.log("data has been sent ", response.data);
    })
    .catch((error) => {

    });
  };
  
return(
  <>
  {toHome ?
  <>
  <Router>
    <Switch>
      <Redirect to="/" /> 
    </Switch>
   </Router> </>: 
   null}
    <header className="toolbar">
        <nav className="toolbar_navigation">
            <div className="toolbar_toggle-button">
                <DrawerToggleButton onClick={props.drawerClickHandler} />
            </div>
            <div className="toolbar_logo"><a href="/">EasyIn</a></div>
            <div className="spacer"/> 
            <div className="toolbar_navigation-items">
                <ul>
                    <li><a href="/Warehouses">Warehouses</a></li>
                    <li><a href="/Departments">Departments</a></li>
                    <li><a href="/Customers">Customers</a></li>
                    <li><a href="/Orders">Orders</a></li>
                    {
                    //<li><a href="/WorkOrders">Work Orders</a></li>
                    }
                    
                    <li><a href="/Settings">Settings</a></li>

                </ul>
            </div>
            
            <a className="btn btn-light btn-md" onClick={submit} href="/signout" role="button">Logout</a>
   
        </nav> 
    </header>
  </>
);
}
export default Toolbar;