import React from "react";
import LoginForm from "../../LoginForm/LoginForm";

function HomePage(){

    return(<div>


    <h2 className="pageTitle">Register</h2>
      <LoginForm 
        type="register"
        />
      
      </div>
    );
}

export default HomePage;

