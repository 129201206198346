import React, { useState, useEffect } from "react";
import axios from "axios";
import "./AddUserForm.css";

import _ from "lodash";

function AddUserForm(props) {
  //ALL STATE VARIABLES DECALRED
  //console.log("THE PROPS " + props.event.dateFor);
  //const [orderProps, setEvent] = useState(props.event);
  const [orderProps] = useState(props.event);
  const departmentId = props.departmentId;
  console.log(" DEPARTMENT IS: ", departmentId);
  const organizationId = props.organizationId;

  //console.dir("props are: " + orderProps.orderList[0].productId);
  const [userForm, setUserForm] = useState({
    fieldNameToAdd: "",
    fieldTypeToAdd: "",
    fieldColumnsQty: "",
    fieldRowsQty: "",
    fieldRowsToAdd: [""],
    fieldColumnsToAdd: [""],
    formName: "",
    fields: []
  });

  //let i = 0;

  const [eventTimeLog, setTimeLog] = useState({
    time: "",
    date: ""
  })

  useEffect(() => {
    let mounted = true;
    function getForms() {
      axios.get("/" + orderProps.event + "/" + organizationId)
        .then((response) => {
          //console.log("response data is ", response.data);
          if (mounted) {
          }
        })
        .catch(() => {
          alert("Error Retrieving data!");
        });
    }
    getForms();
    return () => {
      mounted = false;
    };

  }, [userForm.fieldColumnsToAdd, orderProps, organizationId]);

  function handleChange(event) {
    event.preventDefault();
    const { name, value } = event.target;
    console.log("name: ", name, " value ", value);

    const now = new Date().toLocaleTimeString();
    //const date = new Date().toLocaleDateString();
    const date = new Date().toUTCString();
    let newArr = [];
    let index;

    //PUSH COLUMNS TO ARRAY NICELY
    if (name.includes("fieldColumnsToAdd")) {
      index = parseInt(name.substring(17));
      newArr = userForm.fieldColumnsToAdd;
      newArr[index] = value;
      setUserForm(prevPost => {
        return {
          ...prevPost,
          fieldColumnsToAdd: newArr
        };
      });
    }
    //PUSHES ROWS TO ARRAY NICELY
    else if (name.includes("fieldRowsToAdd")) {
      index = parseInt(name.substring(14));
      newArr = userForm.fieldRowsToAdd;
      newArr[index] = value;
      setUserForm(prevPost => {
        return {
          ...prevPost,
          fieldRowsToAdd: newArr
        };
      });
    }

    else {
      setUserForm(prevPost => {
        return {
          ...prevPost,
          [name]: value
        };
      });
    }
    setTimeLog({
      time: now,
      date: date
    })
  }

  function handleAddField(event) {
    event.preventDefault();
    const { name, value } = event.target;
    console.log("name: ", name, " value ", value);

    if (name === "addTable") {
      console.log("inside add table case", userForm.fieldColumnsQty);
      let fieldTitle=userForm.fieldTitle;
      let columns = userForm.fieldColumnsToAdd;
      let rows = userForm.fieldRowsToAdd;
      let fields = userForm.fields;

      fields.push({
        type: "table",
        fieldTitle: fieldTitle,
        rows: rows,
        columns: columns
      })
      let columnTitle;

      setUserForm(prevPost => {
        return {
          ...prevPost,
          fieldTypeToAdd: "",
          fieldTitle:"",
          fieldColumnsQty: "",
          fieldColumnsToAdd: [],
          fieldRowsQty: "",
          fieldRowsToAdd: [],
          fields: fields
        }
      });
    }
  }

  function onKeyPress(event) {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  }

  function handleSubmitForm(event) {
    //event.preventDefault();
    //const { name, value } = event.target;
    const payload = {
      userForm,
      organizationId,
      eventTimeLog,
      departmentId
    };

    axios({
      url: "/" + orderProps.event + "/" + organizationId,
      method: "POST",
      data: payload
    })
      .then(() => {
        console.log("data as been sent", payload);
        //setToDash(true);
        //console.log(toDash);
        //window.location.reload(true);
        //getProduct();
      })
      .catch(() => {
        console.log("server error");
      });
  };

  //console.log(invItem);

  return (
    /* This doesnt work from dashboard but everything seems fine without... 
    { toDash ? <> <Switch> 
      <Redirect to={("/warehouses/", invItem.warehouse)} /> </Switch>  </>: 
      null}
      */
    <>
      {console.log("The user form is: ", userForm)}
      <div className="addFormCustom">

        <h2>NEW USER FORM</h2>

        <form onKeyPress={onKeyPress}>

          <div id="" className="">

            Form Name
            <input
              type="text"
              className=""
              name="formName"
              placeholder="Form Name"
              value={userForm.formName}
              onChange={handleChange}
            />
            <br />

            {userForm.formName.length > 0 ?
              <>
                New Field:
                <select
                  name="fieldTypeToAdd"
                  value={userForm.fieldTypeToAdd}
                  onChange={handleChange}
                  placeholder="Select">
                  <option > Select </option>
                  {//<option > Text </option>
                  }
                  <option> Table </option>
                </select>

                {userForm.fieldTypeToAdd === "Text" ?
                  <>
                    <input
                      type="text"
                      className=""
                      name="fieldNameToAdd"
                      placeholder="Field Name"
                      value={userForm.fieldNameToAdd}
                      onChange={handleChange}
                    />
                  </> : null}

                {userForm.fieldTypeToAdd === "Table" ?
                  <>
                    <div>
                      Title of Field
                      <input
                        type="string"
                        className=""
                        name="fieldTitle"
                        placeholder="Field Title (optional)"
                        value={userForm.fieldTitle}
                        onChange={handleChange}
                      />
                    </div>
                    <div>
                      Ammount of Columns
                      <input
                        type="number"
                        className=""
                        name="fieldColumnsQty"
                        placeholder="# of Columns"
                        value={userForm.fieldColumnsQty}
                        onChange={handleChange}
                      />
                    </div>
                    Ammount of Rows
                    <input
                      type="number"
                      className=""
                      name="fieldRowsQty"
                      placeholder="# of Rows"
                      value={userForm.fieldRowsQty}
                      onChange={handleChange}
                    />
                    {userForm.fieldColumnsQty > 0 && userForm.fieldRowsQty > 0 ?
                      <>
                        Column Titles:
                        {
                          _.times(userForm.fieldColumnsQty, i => (

                            <div key={i + "c"}>
                              <input
                                type="text"
                                name={"fieldColumnsToAdd" + i}
                                placeholder={"Column " + eval(i + 1)}
                                value={userForm.fieldColumnsToAdd[i]}
                                onChange={handleChange}
                              />
                            </div>

                          ))
                        }

                        Row Titles:
                        {
                          _.times(userForm.fieldRowsQty, (i) => (
                            <div key={i + "r"}>
                              <input
                                type="text"
                                name={"fieldRowsToAdd" + i}
                                placeholder={"Row " + eval(i + 1)}
                                value={userForm.fieldRowsToAdd[i]}
                                onChange={handleChange}
                              />
                            </div>

                          ))
                        }
                        <button onClick={handleAddField} name="addTable"> Add Table </button>
                      </> :
                      null}

                  </> :
                  null
                }

                {userForm.fields.length > 0 ?
                  <>
                    {_.times(userForm.fields.length, i => (
                      <div key={"field" + i}>
                        {userForm.fields[i].fieldTitle}
                        {" "}
                        {userForm.fields[i].type}
                        {userForm.fields[i].type === "table" ?
                          <>
                            {" "}
                            {userForm.fields[i].columns.length}
                            {" x "}
                            {userForm.fields[i].rows.length}
                          </>
                          : null
                        }
                      </div>
                    ))}
                    <button onClick={handleSubmitForm}>Add Form</button>
                  </> : null}
              </> : null}
          </div>

        </form>
      </div>
    </>
  );

}

export default AddUserForm;