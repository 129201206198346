import React, {useState}  from "react";
import axios from "axios";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import "./ListItem.css";

function ListItem(props){ 
  const organizationId = props.organizationId;
  const warehouseId = props.warehouseId;
  const lotTracking = props.lot;

  const deleteVerifcation = "DELETE "+ props.name;
  //console.log(deleteVerifcation);
  const [userVerification, setDeleteVerification] = useState({
    boolean: false,
    inputVerification: "",
    deleteVerifcation: deleteVerifcation
  })  
  function handleClick(event){
    //event.persist();
    
    const payload = {
      updateVar: event.target.name,
      id: props.id,
      organizationId: organizationId,
      warehouseId: warehouseId
    }

    axios({
      url: "/"+props.delurl,
      method: "POST",
      data: payload
    })
      .then(() => {
        //console.log("data as been sent:", event.target.name);
        window.location.reload(true);
        //getProduct();
      })
      .catch(() => {
        console.log("server error");
      });
  }

  function handleChange(event, index) {
    let { name, value } = event.target;
    console.log("Name: ", name, " value: ", value);
    
    setDeleteVerification(prevPost => {
          return {
            ...prevPost,
            [name]: value
          };
      });
console.log(userVerification);
  }

  function handleClose(){
    setDeleteVerification({boolean:false});
  }

  function sendDeleteWarehouse(event){
    if (event.target.name === "Delete") {
      //window.location.replace("/ingredients")
      event.persist();
      setDeleteVerification(prevPost => {
        return {
          ...prevPost,
          boolean: true
        };
    });

    }
  }


    return(<>
    {userVerification.boolean ? 
    <>
    {console.log(userVerification)}
          <Dialog open={true} onClose={handleClose}>
            <DialogContent>
              <h2>
            Please verify delete request. 
            <br/>
            Type "{deleteVerifcation}"
            </h2>
            <br/>
            <input
            value = {userVerification.userVerification}
            onChange= {handleChange}
            name="inputVerification">
            </input>
            {userVerification.deleteVerifcation === userVerification.inputVerification ? <button className="btn-sm btn-danger deleteBut" name="Delete" value="Delete" onClick={handleClick}><a href={props.delUrl}>X</a></button> : <p>Please Verify.</p> }
            </DialogContent>
        </Dialog>
    </>
    :
    null}
    <div className="listItem">
    
    <h3 className="titleLink"> <a href={props.url}>{props.name}</a></h3>
    <h5 className="descriptionObject">{/*console.log(lotTracking)*/} {lotTracking ? "Lot Tracking Warehouse" : null}</h5>
    <button className="btn-sm btn-danger deleteBut" name="Delete" value="Delete" onClick={sendDeleteWarehouse}><a href={props.delUrl}>X</a></button>
  
    </div>
    </>
    );

}

export default ListItem;