import React, { useState, useEffect } from "react";
import axios from "axios";
import "./AddProductForm.css";
//import {parseBarcode} from "gs1-barcode-parser";

//import BarcodeScannerComponent from "react-webcam-barcode-scanner";

import SingleLineForm from "../SingleLineForm/SingleLineForm"

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import CameraIcon from '@material-ui/icons/PhotoCamera';

function AddProductForm(props) {
  const organizationId = props.organizationId;
  console.log(props);
  const lotTrack = props.lot;
  const [invItem, setProduct] = useState({
    itemName: "",
    warehouse: props.props,
    itemCode: "",
    sku: "",
    manufacturer: "",
    description: "",
    inventory: "",
    event: props.invEvent
  });

  /*
  const barcodeParser = new BarcodeParser({
    readers: [
      Symbologies.Code39,
      Symbologies.ITF8,
      Symbologies.GTINX
    ],
    readerConfigurations: []
  });
*/

  //const [toDash, setToDash] = useState(false);

  const [addLocation, setAddLocation] = useState(false);

  const [scanner, setScanner] = useState(false);
  const [scannerName, setScannerName] = useState("");

  const [data, setData] = useState('Not Found');

  const [locationList, setLocationList] = useState({
    list: []
  });

  const [eventTimeLog, setTimeLog] = useState({
    time: "",
    date: ""
  });

  let parsedata;
  useEffect(() => {
    let mounted = true;
    //console.log("1st mounted is ", mounted);
    function getLocations() {
      axios.get("/locations/" + invItem.warehouse)
        .then((response) => {
          const resdata = response.data;
          const found = resdata.find(function (warehouse, index) {
            if (warehouse._id === props.props) {
              return true;
            }
            return warehouse.name;
          });
          const locations = found.locations
          //console.log("Found is ", locations);
          if (mounted) {
            setLocationList({ list: locations });
          }
        })
        .catch(() => {
          alert("Error Retrieving data!");
        });
    }
    getLocations();
    return () => {
      mounted = false;
    };

  }, [invItem.warehouse, locationList.list.length, props.props]);

  /*
  function setLocations(event){
    console.log(event);
    setLocationList({locations: event});
    console.log(locationList);
  }*/


  function handleChange(event) {
    const { name, value } = event.target;

    //parsedata = BarcodeParser.parse(value);

    console.log(value.length);
    /*
    if (name === "sku") {
      console.log("OK ITS INSIDE");
      try {
        var barcode = value;
          let answer = parseBarcode(barcode);
          console.log("parsed ", answer);
        // handle the answer ...    
      } catch (e) {
        alert(e);
      }
      //console.log(parseBarcode(']I010734074010258'));
      //console.log("da data is; ", parsedata);
      // handle the answer ...    

    }*/
    const now = new Date().toLocaleTimeString();
    //const date = new Date().toLocaleDateString();
    const date = new Date().toUTCString();

    setProduct(prevPost => {
      return {
        ...prevPost,
        [name]: value
      };
    });

    setTimeLog({
      time: now,
      date: date
    })
  }

  function onKeyPress(event) {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  }

  function handleClose() {
    setAddLocation(false);
  }

  function handleAddLocation(event) {
    event.preventDefault();
    setAddLocation(true);
  }

  function handleScannerOpen(event) {
    const { name, value } = event.target;
    console.log("event is ", name, value);
    event.preventDefault();
    setScannerName(name);
    if (scanner === false) {
      setScanner(true);
    } else
      setScanner(false);
  }

  function handleCameraScan(input) {
    //event.preventDefault();

    setData("inside scan, ", input);
    console.log("input is; ", input);
    console.log("scanner name ", scannerName);
    //parsedata = BarcodeParser.parse(input);
    console.log(parsedata);
    setProduct(prevPost => {
      return {
        ...prevPost,
        [scannerName]: input
      };
    });
    setScanner(false);
  }

  function submit(event) {
    event.preventDefault();
    const userId = window.localStorage.getItem("userId");
    //const { name, value } = event.target;
    const payload = {
      invItem,
      organizationId,
      eventTimeLog,
      userId
    };

    axios({
      url: "/newItem",
      method: "POST",
      data: payload
    })
      .then(() => {
        console.log("data as been snet0", payload);
        //setToDash(true);
        //console.log(toDash);
        window.location.reload(true);
        //getProduct();
      })
      .catch(() => {
        console.log("server error");
      });
  };

  //console.log(invItem);

  return (
    /* This doesnt work from dashboard but everything seems fine without... 
    { toDash ? <> <Switch> 
      <Redirect to={("/warehouses/", invItem.warehouse)} /> </Switch>  </>: 
      null}
      */
    <>
      <script src="./src/BarcodeParser.js"></script>
      <div className="form">
        {scanner ?
          <>
          {/*
            <BarcodeScannerComponent
              width={350}
              height={500}
              name={scannerName}
              value={scannerName}
              onUpdate={(err, result) => {
                if (result) {
                  handleCameraScan(result.text)
                }
                else setData('Not Found')
              }}
           
              />
 */}
            <button className="" onClick={handleScannerOpen}>Close Camera</button>
            <p>{data}</p>
          </>

          : null}
        <>
          <Dialog open={addLocation} onClose={handleClose}>
            <DialogContent>
              <SingleLineForm
                props={props}
                placeholder={"Location"}
                checkboxcaption={false}
                checkBoxValue={false}
                name="newLocation"
                url="/addLocation" />
            </DialogContent>
          </Dialog>
        </>
        <div >
          <h2>NEW ITEM FORM</h2>
          <div className="">
            <form onKeyPress={onKeyPress}>
              <div>

                <div id="" className="customRowContainer">
                  <select
                    className="selectField"
                    type="text"
                    name="location"
                    placeholder="Location"
                    onChange={handleChange}
                    value={invItem.inventory.location}>

                    <option>Location</option>
                    {locationList.list.map((listItem, index) => {
                      return (
                        <option key={index}>{listItem}</option>
                      )
                    })}
                  </select>
                  <button id="selectAdd" className="addButton" onClick={handleAddLocation}>+</button>
                </div>

                <input
                  type="text"
                  className="inputField"
                  name="itemName"
                  placeholder="Enter Item Name"
                  value={invItem.itemName}
                  onChange={handleChange}
                />

                <div className="customRowContainer" >
                  <input
                    type="text"
                    className="smallInputField"
                    name="sku"
                    placeholder="Enter SKU"
                    value={invItem.sku}
                    onChange={handleChange}
                  />

                  <button className="addButton" value="sku" name="sku" onClick={handleScannerOpen}><CameraIcon/></button>
                </div>

                <input
                  type="text"
                  className="inputField"
                  name="manufacturer"
                  placeholder="Enter manufacturer"
                  value={invItem.manufacturer}
                  onChange={handleChange}
                />

                <input
                  type="text"
                  className="inputField"
                  name="description"
                  placeholder="Enter description"
                  value={invItem.description}
                  onChange={handleChange}
                />

                <input
                  type="number"
                  className="inputField"
                  name="inventory"
                  placeholder="Starting inventory value"
                  value={invItem.inventory}
                  onChange={handleChange}
                />

                {lotTrack ?
                  <div id="container">

                    <input
                      type="text"
                      className="smallInputField"
                      name="lot"
                      placeholder="Associated Lot "
                      value={invItem.lot}
                      onChange={handleChange}
                    />
                    <button className="addButton" value="lot" name="lot" onClick={handleScannerOpen}><CameraIcon/></button>
                  </div>
                  :
                  null
                }

              </div>

              <button className="submitButton" onClick={submit}>Submit</button>
            </form>
          </div>
        </div>
      </div>
    </>
  );

}

export default AddProductForm;