import React from "react";

function HomePage(){

    return(<div>
    
    <h2 className="pageTitle">Welcome To EasyIn</h2>
    
    <div className="feature-titles">
      <p>
      <br></br> You're here early! <br></br> You must be really excited to start managing your inventory.  <br></br> We're only in Beta stages but feel free to register and give EasyIn a shot. 
      </p>
    </div>
    
    <section className="colored-section" id="features">
  <div className="container-fluid">

  <div className="row">
    <div className="col-lg-4 col-md-12 feature-box">
      <i className="fas fa-check-circle fa-4x  midicons"></i>
      <h3 className="feature-titles">Easy to use.</h3>
      <p>We started from scratch.  <br></br>  We focused on the necessities for inventory tracking  <br></br>  making EasyIn exceptionally simple and easy to use. </p>
    </div>
    <div className="col-lg-4 col-md-12 feature-box">
      <i className="fas fa-bullseye fa-4x midicons"></i>
      <h3 className="feature-titles">Beta</h3>
      <p>Don't get too comfortable.  <br></br>  We're just about set up and not even getting started!  <br></br>  Lot tracking is in the works. Go ahead give EasyIn a go, its free.</p>
    </div>
    <div className="col-lg-4 col-md-12 feature-box">
      <i className="fas fa-heart fa-4x  midicons"></i>
      <h3 className="feature-titles">Free account.</h3>
      {
      //<p>It's too good to be true and only getting better... <br></br>  We're letting anyone try EasyIn for free.<br></br> <i>Free accounts support; 1 user & 2 warehouses with up to 20 items each.</i></p>
      }
      <p>Contact our Administrator about registering during beta phases. </p>
      </div>
  </div>

  </div>
</section>




      </div>
    );
}

export default HomePage;

