import React, { useState, useEffect } from "react";
import axios from "axios";
import "./DashboardPage.css";
import NewUserForm from "../../NewUserForm/NewUserForm";

import QuickEventForm from "../../QuickEventForm/QuickEventForm";

import AddOrderForm from "../../AddOrderForm/AddOrderForm"

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import "../../../index.css";

function DashboardPage(props) {
  //console.log("props ", props);
  let organizationId = props.id;
  let bakeryName = props.name;
  //console.log(bakeryName)

  const [headingText, setHeading] = useState("");
  const [organization, setOrganization] = useState("");

  const [addOrder, setAddOrder] = useState(false);

  const [exiting, setExiting] = useState(false);

  useEffect(() => {
    let mounted = true;
    setHeading(bakeryName + "'s");
    function getBakery() {
      axios.get("/organization/" + organizationId, {
      })
        .then((response) => {
          if (mounted) {
            //WILL GET BAKERY DATA WHEN THERE IS DATA 
            //console.log("data has been received : ", response.data);
            setOrganization({
              warehouses: response.data.warehouses
            });
            //console.log("organization info: ", organization);
          }
        })
        .catch(() => {
          alert("Error Retrieving data!");
        });
    }
    getBakery();
    return () => {
      mounted = false;
    };
    //console.log("Used Effect");
  }, [organizationId, bakeryName]);

  function addOrderEvent() {
    //console.log("inside event add order");
    setAddOrder(true);
  }

  const handleClose = () => {
    setAddOrder(false);
    setExiting(false);
  };

  const exitFun = () => {
    setExiting(true);
  }

  const stayFunction = () => {
    setExiting(false);
  }
  const [theme, setTheme] = useState("dark");

  return (
    <div  >
      <h2 className="pageTitle"> {headingText} Dashboard</h2>

      {headingText === "NewUser's" ?
        <NewUserForm /> :
        <>
          <button onClick={addOrderEvent}>Add Order</button>
          <div className="">
            {addOrder ?
              <Dialog open={addOrder} onClose={exitFun}>
                <DialogContent>

                  {exiting ?
                    <>
                      <div className="exitingPrompt">
                        You are exiting this form. All changes will be lost.
                        <br />
                        <button className="exitButton" onClick={handleClose}> Exit </button>
                        <>   </>
                        <button className="stayButton" onClick={stayFunction}> Stay </button>
                      </div>
                    </> : null
                  }

                  <AddOrderForm
                    organizationId={organizationId}
                    event={{ event: "newOrder" }}>

                  </AddOrderForm>


                </DialogContent>
              </Dialog>
              : null
            }
            {organization.warehouses !== undefined
              ? organization.warehouses.map((listItem, index) => {
                return (
                  <div key={index} className="col-lg-4 col-md-12 feature-box app" >
                    <QuickEventForm
                      name={listItem.warehouseName}
                      id={listItem._id}
                      organizationId={organizationId}
                      lot={listItem.lotTracking}
                      warehouseList={organization.warehouses}
                      key={index} />
                  </div>
                )
              })
              : null
            }
          </div>
        </>
      }
      {"\n"}
    </div>
  );
}

export default DashboardPage;

