import React, { useState, useEffect } from "react";
//import { Link } from 'react';
import axios from "axios";
import moment from "moment";
//import ListItem from "../../ListItem/ListItem";
import "./UserFormsPage.css";

import MaterialTable from '@material-table/core';

import Add from '@material-ui/icons/Add';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';

import AddUserForm from "../../AddUserForm/AddUserForm"

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import DeleteForm from "../../DeleteForm/DeleteForm";

import UserForm from "../../UserForm/UserForm";

function UserFormsPage(props) {
  const organizationId = props.id;
  const departmentId = props.departmentId;
  console.log(" DEPARTMENT IS: ", departmentId);
  //console.log("organzaiton id ", organizationId);
  let warehouse = props.id;

  const [list, setList] = useState({
    forms: []
  })

  //const [warehouseObject, setWarehouse] = useState({});
  const [warehouseObject] = useState({});

  const [event, setEvent] = useState("");

  const [exiting, setExiting] = useState(false);

  useEffect(() => {
    let mounted = true;
    function getProduct() {
      const payload = {
        organizationId
      };
      axios({
        url: "/getdepartmentForms/" + departmentId,
        method: "GET",
        data: payload
      })
        .then((response) => {
          const resdata = response.data;
          const userForms = resdata.userForms;

          console.log("response is forms : ", resdata);
          if (mounted) {
            setList({
              forms: userForms,
            });

          }
        })
        .catch(() => {
          alert("Error Retrieving data!");
        });
    }
    getProduct();
    return () => {
      mounted = false;
    };
  }, [warehouse, organizationId]);

  const columns = [
    {
      title: "Form",
      field: "formName",
      //render: row => ({row.formName})
      //render: row => (<a href={"/form/" + row._id}>{row.formName}</a>)
      //customFilterAndSearch: "",
    }
  ]

  const historyColumns = [
    {
      title: "History",
      field: "dateMade",
      type: "datetime",
      //render: row => (<> {row.dateMade} </>)

      defaultSort: "desc",
      render: row => (<a href={"/postedForm/" + row._id}>{moment(row.dateMade).format("LLL")}</a>)
      // render: ({ row }) => (<div> row.itemName </div>)
    }
    /*,
    {
      title: "Date",
      field: "dateMade",
      type: "datetime",
      dateSetting: {
        format: 'dd/MM/yyyy hh:mm'
      },
      defaultSort: "desc"
    }
    */
  ]

  const handleClose = () => {
    setEvent({
      bool: false,
      event: ""
    });
    setExiting(false);
  };

  const exitFun = () => {
    setExiting(true);
  }

  const stayFunction = () => {
    setExiting(false);
  }

  return (
    <div>
      <>
        {event ?
          <>
            <Dialog open={event.bool} onClose={exitFun}
              fullWidth
              maxWidth="lg">
              <DialogContent>

                {exiting ?
                  <>
                    <div className="exitingPrompt">
                      You are exiting this form. All changes will be lost.
                      <br />
                      <button className="exitButton" onClick={handleClose}> Exit </button>
                      <>   </>
                      <button className="stayButton" onClick={stayFunction}> Stay </button>
                    </div>
                  </> : null
                }

                {
                  event.event === "newForm" ?
                    <AddUserForm
                      departmentId={departmentId}
                      organizationId={organizationId}
                      event={event}>
                    </AddUserForm> :
                    null
                }
                {
                  event.event === "delete" && exiting !== true ?
                    <DeleteForm
                      organizationId={organizationId}
                      objectName={event.objectName}
                      delMessage={event.delMessage}
                      delURL={event.delURL}>

                    </DeleteForm> :
                    null
                }
                {
                  event.event === "fillForm" ?
                    <UserForm
                      organizationId={organizationId}
                      event={event}
                      fields={event.fields}
                      edit={true}
                    >

                    </UserForm> :
                    null
                }

              </DialogContent>
            </Dialog>

          </> :
          null}
      </>

      <h2 className="pageTitle"> {warehouseObject.warehouseName}
        <div fontSize="0.5px">
          {warehouseObject.lotTracking
            ? <> A Lot tracking warehouse </>
            : null
          }

        </div>
      </h2>

      <MaterialTable
        className="Waretable"
        title="User Forms"
        data={list.forms}
        columns={columns}
        /*
        editable={{
          onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              const dataUpdate = [...data];
              const index = oldData.tableData.id;
              dataUpdate[index] = newData;
              setData([...dataUpdate]);
 
              resolve();
            }, 1000)
          })
        }}*/
        actions={[
          {
            icon: Add,
            tooltip: 'New Form',
            isFreeAction: true,
            onClick: () => {
              setEvent({
                bool: true,
                event: "newForm"
              });
              //console.log(event);
            }
          },
          /* EDIT FORM TO BE ADDED
          {
            icon: Edit,
            tooltip: 'Edit Order',
            onClick: (event, rowData) => {
              setEvent({
                bool: true,
                event: "fillForm",
                orderId: rowData._id,
                fields: rowData.fields
              });
            }
          },*/
          {
            icon: Add,
            tooltip: 'Fill Form',
            onClick: (event, rowData) => {
              setEvent({
                bool: true,
                event: "fillForm",
                formName: rowData.formName,
                id: rowData._id,
                fields: rowData.fields
              });
            }
          },
          {
            icon: Delete,
            tooltip: 'Delete Order',
            onClick: (event, rowData) => {
              setEvent({
                bool: true,
                event: "delete",
                itemId: rowData._id,
                delURL: "/deleteForm/" + rowData._id,
                objectName: rowData.formName,
                delMessage: "Deleting this user form cannot be undone and will delete the form and all history corresponding to it. Are you sure you want to continue?"
              });
            }
          }
        ]}
        options={{
          search: true,
          titleStyle: {
            fontWeight: 'bold',
            fontSize: '4rem'
          },
          headerStyle: {
            backgroundColor: '',
            fontWeight: 'bold',
            fontSize: '2rem'
          },
          rowStyle: {
            backgroundColor: '#EEE',
            fontSize: '2rem'
          },
          title: { backgroundColor: '#EEE' },
          paging: false,
          filtering: false,
          exportButton: true,
          actionsColumnIndex: -1
        }}
        detailPanel={[
          {
            tooltip: 'Show Details',
            render: rowData => {
              return (
                <div
                  style={{
                    backgroundColor: '#EEE',
                    fontSize: '2rem',
                    padding: "2rem"
                  }}
                >
                  <div>
                    Description: {rowData.rowData.createdOn} <br></br>
                  </div>

                  <MaterialTable
                    className="Waretable"
                    title="Products"
                    //data= {rowData}
                    data={rowData.rowData.history}
                    columns={historyColumns}
                    actions={[
                      {
                        icon: Delete,
                        tooltip: 'Delete Order',
                        onClick: (event, rowData) => {
                          setEvent({
                            bool: true,
                            event: "delete",
                            itemId: rowData._id,
                            delURL: "/deleteFormHistory/" + rowData._id,
                            objectName: "History",
                            delMessage: "Deleting this user history cannot be undone and will delete the form and all history corresponding to it. Are you sure you want to continue?"
                          });
                        }
                      }
                    ]}
                    options={{
                      search: true,
                      titleStyle: {
                        fontWeight: 'bold',
                        fontSize: '4rem'
                      },
                      headerStyle: {
                        backgroundColor: '',
                        fontWeight: 'bold',
                        fontSize: '2rem'
                      },
                      rowStyle: {
                        backgroundColor: '#EEE',
                        fontSize: '2rem'
                      },
                      title: { backgroundColor: '#EEE' },
                      paging: true,
                      filtering: false,
                      exportButton: true,
                      actionsColumnIndex: -1
                    }}
                  />
                </div>
              )
            },
          },
        ]}
      />
      <br />
    </div>
  );
}
export default UserFormsPage;