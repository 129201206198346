import React from "react";

import "./SideDrawer.css";

const GuestSideDrawer = props => {
    let drawerClasses = ["side-drawer"];
    if (props.show){
        drawerClasses = ["side-drawer open"];
    }
return(
    <nav className={drawerClasses}>
        <ul>
            <li><a href="/">Home</a></li>
        </ul>
    </nav>
)
};

export default GuestSideDrawer;