import React, { useState, useEffect } from "react";
import axios from "axios";
import "./QuickEventForm.css";

import { BrowserRouter as Switch, Redirect } from "react-router-dom"

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import AddProductForm from "../AddProductForm/AddProductForm"

//import BarcodeScannerComponent from "react-webcam-barcode-scanner";

import InvEventForm from "../InvEventForm/InvEventForm";

function QuickInvEventForm(props) {
  //console.log("props name ", props);
  const id = props.id;
  const organizationId = props.organizationId;
  const name = props.name;
  const lot = props.lot;
  const [event, setEvent] = useState("");
  //const warehouseList = props.warehouseList;

  const [warehouseIndex, setWarehouseIndex] = useState(null);

  const [warehouse] = useState({
    id: id,
    name: name,
    items: []
  })
  const [warehouseObject, setWarehouseObject] = useState({
  })
  /*
  const [eventTimeLog, setTimeLog]= useState({
    time: "",
    date: ""
  })
*/
  const [itemList, setItemList] = useState({
    list: []
  });

  const [invItem, setItem] = useState({
    itemName: "Select Item",
    sku: ""
  });

  const [invEvent, setInvEvent] = useState("");

  const [scanner, setScanner] = useState(false);
  const [camera, setCamera] = useState(false);

  const [data, setData] = useState('Not Found');

  const [exiting, setExiting] = useState(false);

  function handleEventChange(event) {
    const name = event.target.name;
    setEvent(name);
    setInvEvent(prevPost => {
      return {
        ...prevPost,
        event: name
      }
    })
    //console.log("Name is :",name);
    if (name === undefined) {
      setScanner(false);
    }
  }

  useEffect(() => {
    let mounted = true;
    function getProduct() {
      const payload = {
        organizationId
      };
      axios({
        url: "/warehouse/" + id,
        method: "GET",
        data: payload
      })
        .then((response) => {
          const resdata = response.data;
          const warehouses = resdata.organization[0].warehouses;
          //console.log("response is: ", resdata, "warehouses ", warehouses);
          if (mounted) {
            setItemList({ items: resdata.items });
            const lotTrack = warehouses.find(item => item._id === id)
            //console.log("lot Track is ", lotTrack);
            setWarehouseObject(lotTrack);
            if (!lot || lot) {
              setWarehouseIndex(0);
            }

            //console.log("warehouse data received: ", resdata.items);
          }
        })
        .catch(() => {
          alert("Error Retrieving data!");
        });
    }
    getProduct();
    return () => {
      mounted = false;
    };
  }, [id, organizationId, lot]);


  function handleItemSelect(event) {
    const itemId = event.target.value;
    handleSetItem(itemId);
    //console.log("item is set to: ", invItem);
  }

  function handleScannerOpen(event) {
    event.preventDefault();
    if (scanner === false) {
      setScanner(true);
    } else
      setScanner(false);
  }

  function handleCameraScan(input) {
    //event.preventDefault();
    //console.log("input is; ", input);
    setData("inside scan, ", input);
    const itemSelected = itemList.items.find(item => item.sku === input);
    if (itemSelected !== undefined) {
      //console.log("item is, ", itemSelected);
      handleSetItem(itemSelected._id);
      //setItem(itemSelected);
      setCamera(false);
    }
  }

  function handleChange(event) {
    const { name, value } = event.target;
    setItem(prevPost => {
      return {
        ...prevPost,
        [name]: value
      };
    });
  }

  function handleBarcodeScan(event) {
    event.preventDefault();

    const itemSelected = itemList.items.find(item => item.sku === invItem.sku);
    if (itemSelected !== undefined) {
      //console.log("item is, ", itemSelected);
      handleSetItem(itemSelected._id);
      //setItem(itemSelected);
      setScanner(false);
    }
  }

  function handleCamera(event) {
    event.preventDefault();
    if (camera === false) {
      setCamera(true);
    } else
      setCamera(false);
  }

  function handleSetItem(item) {
    //console.log("item id should be ", item);
    const theitem = item;
    if (theitem === "") {
      setItem({ itemName: "Select Item" });
    }
    else {
      const itemSelected = itemList.items.find(item => item._id === theitem);
      setItem(itemSelected);
      //console.log("item is", itemSelected);
      setInvEvent({
        bool: true,
        event: "add",
        itemId: itemSelected._id,
        invValue: itemSelected.inventory[warehouseIndex].warehouseInvTotal,
        location: itemSelected.inventory[warehouseIndex].location,
        inventoryLots: itemSelected.inventory[warehouseIndex].invLots,
        itemName: itemSelected.itemName,
        inventory: itemSelected.inventory,
        warehouseIndex: warehouseIndex
      });
    }
  }

  const exitFun = () => {
    setExiting(true);
  }
  const stayFunction = () => {
    setExiting(false);
  }

  const handleClose = () => {
    setEvent("");
    setExiting(false);
  };
  //console.log(invItem);



  return (
    <>
      <div className="quickInv">
        <h2> <a href={"/Warehouses/" + warehouse.id}>{warehouse.name}</a> </h2>

        <br />
        <div>

          {itemList.items !== undefined ?
            <>
              <select
                type="text"
                name="itemName"
                className="quickSelect"
                placeholder="Item"
                value={invItem._id}
                onChange={handleItemSelect}>
                <option value="">Select Item</option>
                {itemList.items.map((listItem, index) => {
                  return (
                    <option key={index} value={listItem._id}> {listItem.itemName}</option>
                  )
                })}
              </select>
              {scanner ?
                <>
                  <Dialog open={true} onClose={handleEventChange}>
                    <DialogContent>
                      {camera ?
                        <>
                          {/* 
                          <BarcodeScannerComponent
                            width={350}
                            height={500}
                            name="sku"
                            onUpdate={(err, result) => {
                              if (result) {
                                handleCameraScan(result.text)
                              }
                              else setData('Not Found')
                            }}
                          />
                          */}
                          <p>{data}</p>
                          <button className="" onClick={handleCamera}>{camera ? "Close Camera" : "Open Camera"}</button>

                        </> :
                        <>
                          <form>
                            <br></br>
                            <input onChange={handleChange} autoFocus value={invItem.sku} name="sku"></input>
                            <br></br>
                            <button onClick={handleBarcodeScan}>Lookup SKU</button>

                          </form>
                          {
                           // <button className="" onClick={handleCamera}>{camera ? "Close Camera" : "Open Camera"}</button>
                          }
                        </>
                      }

                    </DialogContent>
                  </Dialog>
                </>
                : null}

              <button className="button smallBorder" onClick={handleScannerOpen}>{scanner ? "Close Scan" : "Lookup SKU"}</button>
              {"  "}
              <button className="plusButton" onClick={handleEventChange} name="new">+</button> </> :
            null}

          <br />
          {invItem.itemName !== "Select Item" ?
            <>
              <div className="eventInvInfo">
                Inventory: {invItem.inventory[warehouseIndex].warehouseInvTotal} <br></br>
              </div>
              <button className="button" onClick={handleEventChange} name="add">Add</button>
              {"             "}
              <button className="button" onClick={handleEventChange} name="remove">Remove</button>
              {"             "}
              {//MOVE BUTTON FOR MOVE FUNCTION TO BE IMPLEMENTED
              }
              {//<button className="button" onClick={handleEventChange} name="move">Move</button
              }
              <br />
            </> :
            <>
              <div>
                <br />
                <br />
                <br />

              </div>
            </>
          }

          {//console.log("THE LIST IS: ", itemList, itemList.items)
          }


        </div>

        {event === "new" ?
          <>
            <Dialog open={true} onClose={exitFun}>
              <DialogContent className="">

                {
                  event === "new" ?
                    <>
                      {exiting ?
                        <>
                          <div className="exitingPrompt">
                            You are exiting this form. All changes will be lost.
                            <br />
                            <button className="exitButton" onClick={handleClose}> Exit </button>
                            <>   </>
                            <button className="stayButton" onClick={stayFunction}> Stay </button>
                          </div>
                        </> : null
                      }

                      <AddProductForm
                        warehouseObject={warehouseObject}
                        organizationId={organizationId}
                        invEvent={invEvent.event}
                        lot={lot}
                        props={warehouse.id} />
                    </> :
                    null
                }

              </DialogContent>
            </Dialog>
          </> :
          null
        }

        {event === "add" || event === "remove" || event === "move" ?
          <>
            <Dialog open={true} onClose={exitFun}>
              <DialogContent className="quickAddForm">

                {exiting ?
                  <>
                    <div className="exitingPrompt">
                      You are exiting this form. All changes will be lost.
                      <br />
                      <button className="exitButton" onClick={handleClose}> Exit </button>
                      <>   </>
                      <button className="stayButton" onClick={stayFunction}> Stay </button>
                    </div>
                  </> : null
                }

                <div className="form-input">
                  <h3> {warehouse.name} <br /> {event === "add" ? <> Add form</> : <>Remove Form</>} </h3>
                  <div className="addForm, centerDiv">

                    {invItem.itemName !== "Select Item" ?
                      <>
                        <InvEventForm
                          props={warehouse.id}
                          event={invEvent}
                          warehouseObject={warehouseObject}
                          lot={warehouseObject.lotTracking}
                          invItem={invEvent}
                          fromWarehouseBool={false}
                        />
                      </>
                      : null}

                  </div>
                </div>
              </DialogContent>
            </Dialog>
          </> :
          null
        }

        {null ? <> <Switch>
          <Redirect to={("/warehouses", invItem.warehouse)} /> </Switch>  </> : null}

        <br></br>
      </div>
    </>
  );

}

export default QuickInvEventForm;
