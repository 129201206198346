import React, { useState, useEffect } from "react";
//import { Link } from 'react';
import axios from "axios";
//import ListItem from "../../ListItem/ListItem";
import "./OrderPage.css";

import MaterialTable from '@material-table/core';

import Add from '@material-ui/icons/Add';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';


import AddOrderForm from "../../AddOrderForm/AddOrderForm"
import InvEventForm from "../../InvEventForm/InvEventForm"

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import DeleteForm from "../../DeleteForm/DeleteForm";

function OrderPage(props) {
  console.log("ORDER PAGE IS LOADED")
  const organizationId = props.id;
  //console.log("organzaiton id ", organizationId);
  let order = props.orderId;

  const [list, setList] = useState({
    order: {},
    items: [],
    customer: {}
  })

  //const [warehouseObject, setWarehouse] = useState({});
  const [warehouseObject] = useState({});

  const [event, setEvent] = useState("");


  useEffect(() => {
    let mounted = true;
    function getOrder() {

      const payload = {
        organizationId
      };

      axios({
        url: "/orderRequest/" + order,
        method: "GET"
        //data: payload
      })
        .then((response) => {

          const resdata = response.data;
          const order = resdata.order;
          const customer = resdata.customer;
          const items = resdata.items;

          console.log("response is: ", resdata);
          if (mounted) {

            setList({
              order: order,
              customer: customer,
              items: items
            });

          }
        })
        .catch(() => {
          alert("Error Retrieving data!");
        });
    }
    getOrder();
    return () => {
      mounted = false;
    };
  }, []);


  //const [theItem, setTheItem] = useState({});

  let theItem;
  let sku;
  const columns = [
    {
      title: "Product1",
      //field: "dateFor",
      render: row => (
        <>
          {list.items.map((item, index) => {
            if (item._id === row.productId) {
              theItem = item.itemName;
              console.log("inside condition for " + item);
            }


          })
          }
          {theItem}
        </>
      )
      //render:  row  => (<div> {row.itemName} </div>)
    },
    {
      title: "SKU/GTIN",
      render: row => (
        <>
          {list.items.map((item, index) => {
            if (item._id === row.productId) {
              sku = item.sku;
            }


          })
          }
          {sku}
        </>
      )
    },
    {
      title: "Quantity",
      field: "qty",
      //render:  row  => (console.log("rowData: ", row, " custumerList: ", list.customers ))
      //render: row => (<> {list.customers[list.customers.findIndex((customer) => customer._id === row.customerId)].accountName} </>)
      //render: ({ rowData }) => (<> {console.log("rowData: ", rowData) } </>)

    },
    {
      title: "Price",

    }
  ]

  const handleClose = () => {
    setEvent({
      bool: false,
      event: ""
    });
  };

  return (
    <div>
      <>
        {console.log("the lsit is: ", list)}
        {event ?
          <>
            <Dialog open={event.bool} onClose={handleClose}>
              <DialogContent>
                {
                  event.event === "delete" ?
                    <DeleteForm
                      organizationId={organizationId}
                      objectName={event.objectName}
                      delURL={event.delURL}>

                    </DeleteForm> :
                    null
                }
                {
                  event.event === "edit" ?
                    <AddOrderForm
                      organizationId={organizationId}
                      event={event}
                      edit={true}
                    >

                    </AddOrderForm> :
                    null
                }
                {
                  event.event === "add" || event.event === "remove" ?
                    <>
                      {console.log("were inside here")}
                      <InvEventForm
                        props={order}
                        event={event}
                        warehouseObject={warehouseObject}
                        lot={warehouseObject.lotTracking}
                        invItem={event}
                        fromWarehouseBool={true}
                      />
                    </> :
                    null
                }

              </DialogContent>
            </Dialog>

          </> :
          null}
      </>


      <h2 className="pageTitle"> Order for: {list.customer.accountName}
      </h2>
      <div>
        Invoice: {list.order.invoiceNum} <br />
        Invoice Date: {list.order.dateFor !== undefined ? <> {list.order.dateFor} <br /> </> : null}
        Location: {list.customer.locations !== undefined ? <>{list.customer.locations.map((location, index) => {
          if (location._id === list.order.locationId) {
            return (location.locationName)
          }
        })
        }<br /> </> : null}
        Address Line 1: {list.customer.locations !== undefined ? <> {list.customer.locations.map((location, index) => {
          if (location._id === list.order.locationId) {
            return (location.addressLineOne)
          }
        })
        }<br /> </> : null}
        Address Line 2: {list.customer.locations !== undefined ? <> {list.customer.locations.map((location, index) => {
          if (location._id === list.order.locationId) {
            return (location.addressLineTwo)
          }
        })
        }<br /> </> : null}
        {list.order.commited ? <> COMMITED {list.order.commitedDate!== undefined ? <>{list.order.commitedDate.slice(0,list.order.commitedDate.indexOf('T'))} </> : null}</> : null}
      </div>

      <MaterialTable
        className="Waretable"
        title="Order "
        data={list.order.orderList}
        columns={columns}
        actions={[
          {
            icon: Add,
            tooltip: 'New Item',
            isFreeAction: true,
            onClick: () => {
              setEvent({
                bool: true,
                event: "new"
              });
              //console.log(event);
            }
          },
          {
            icon: Edit,
            tooltip: 'Edit Order',
            onClick: (event, rowData) => {
              setEvent({
                bool: true,
                event: "edit",
                orderId: rowData._id,
                PO: rowData.PO,
                dateFor: rowData.dateFor,
                customerId: rowData.customerId,
                locationId: rowData.locationId,
                warehouseId: rowData.warehouseId,
                orderList: rowData.orderList
              });
            }
          },
          {
            icon: Delete,
            tooltip: 'Delete from Inventory',
            onClick: (event, rowData) => {
              setEvent({
                bool: true,
                event: "delete",
                itemId: rowData._id,
                delURL: "deleteOrder/" + rowData._id,
                objectName: "PO #" + rowData.PO
              });
            }
          }
        ]}
        options={{
          search: true,
          titleStyle: {
            fontWeight: 'bold',
            fontSize: '4rem'
          },
          headerStyle: {
            backgroundColor: '',
            fontWeight: 'bold',
            fontSize: '2rem'
          },
          rowStyle: {
            backgroundColor: '#EEE',
            fontSize: '2rem'
          },
          title: { backgroundColor: '#EEE' },
          paging: false,
          filtering: false,
          exportButton: true,
          actionsColumnIndex: -1
        }}
        detailPanel={[
          {
            tooltip: 'Show Details',
            render: rowData => {
              return (
                <div
                  style={{
                    backgroundColor: '#EEE',
                    fontSize: '2rem',
                    padding: "2rem"
                  }}
                >


                  <div>
                    <table>
                      <tr>
                        <th className="lotTable">Lot</th>
                        <th className="lotTable">Quantity</th>
                      </tr>
                      {rowData.rowData.commitedLots.map((lot, index) => {
                        return (
                          <tr key={index}>
                            <td>{lot.lot}</td>
                            <td>{lot.qty}</td>
                          </tr>
                        )
                      })}
                    </table>
                  </div>
                  {console.log("Row data: ", rowData)}

                </div>
              )
            },
          },
        ]}
      />
      <br />
    </div>
  );
}
export default OrderPage;