import React from "react";
import "./Footer.css";

import FavoriteIcon from '@material-ui/icons/Favorite';
function Footer(){
  
// Return today's date and time
var currentTime = new Date();
// returns the year (four digits)
var year = currentTime.getFullYear();

  return (
    <footer>
    <p className="footer">
      Copyright {year}. Made with <FavoriteIcon fontSize="small"/> by Bruce
    </p>
    </footer>
  );
  }
export default Footer;