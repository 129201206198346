import React, { useState, useEffect } from "react";
import axios from "axios";
import "./InvEventForm.css";

import { BrowserRouter as Switch, Redirect } from "react-router-dom"

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import SingleLineForm from "../SingleLineForm/SingleLineForm"
import { isEmpty, isNaN } from "lodash"; //can import isUndefined if needed as well...

//import BarcodeScannerComponent from "react-webcam-barcode-scanner";

function InvEventForm(props) {
  //console.log("PROPS FOR INV EVENT ARE ", props.event);
  const itemName = props.invItem.itemName;

  const warehouseList = props.warehouseList;
  //console.log(warehouseList)
  //const inventory = props.event.inventory;
  const event = props.event.event;
  const invValue = props.event.invValue;

  const itemIdVal = props.event.itemId;
  const warehouseid = props.props;
  const lot = props.lot; //Is it a lot tracking item? 

  let lotIdForNonLotTracked;
  if (event !== "delete") {
    if (props.invItem.inventory[0].invLots.length > 0) {
      lotIdForNonLotTracked = props.invItem.inventory[0].invLots[0]._id
    }
  }

  const warehouseIndex = props.event.warehouseIndex;
  const inventory = props.event.inventory;
  //console.log("initial inv is; ", inventory, lot );
  let locationList = [];

  let location;
  if (lot) {
    location = "";
  } else {
    location = props.event.location;
  }

  //ORIGINAL LINE BEFORE MULTIPLE WAREHOUSES
  //const numberOfLots = props.invItem.inventoryLots.length;

  let numberOfLots = 0;
  //console.log(numberOfLots);

  const lots = props.event.inventoryLots;
  let lotsqty = [];


  if (props.warehouseObject.locations !== undefined) {
    locationList = props.warehouseObject.locations;
  } else {
    locationList = [];
  }

  const [locations] = useState({
    list: locationList
  });


  const [addLocation, setAddLocation] = useState(false);

  const [invItem, setProduct] = useState({
    itemName: itemName,
    event: event,
    itemId: itemIdVal,
    _id: itemIdVal,
    warehouse: warehouseid,
    invAdjustment: "",
    newQty: "",
    invTotal: invValue,
    newInv: 0,
    location: location,
    lot: "", //LOT will be for invItem being stored w new lot
    lots: lots, //IS array of ALL LOTS for inv item
    lotAdjustments: [],
    lotId: "", //FOR NOT LOT TRACKED ITEMS TO SET IN MONGOOSE
    newLotInv: lotsqty,
    updatedLotInv: [],
    warehouseSelect: undefined,
    inventory: inventory
  });

  const [nonLotTrackedLotId, setNonLotTrackedLotId] = useState();



  if (lot && event !== "delete") {
    //console.log("invItem here is ",invItem);
    numberOfLots = props.invItem.inventory[warehouseIndex].invLots.length;
    lotsqty = lots.map(a => a.qty);
  }

  const [lotAdjustment, setLotAdjustment] = useState([]);
  const [newLot, setNewLot] = useState({
    location: ""
  });
  const [eventTimeLog, setTimeLog] = useState({
    time: "",
    date: ""
  })

  const deleteVerifcation = "DELETE " + itemName;
  //console.log(deleteVerifcation);
  const [userVerification, setDeleteVerifcation] = useState({
    inputVerification: "",
    deleteVerifcation: deleteVerifcation
  })

  const [toDash, setToDash] = useState(false);

  const [scanner, setScanner] = useState(false);
  const [scannerName, setScannerName] = useState("");
  const [data, setData] = useState('Not Found');



  useEffect(() => {
    let mounted = true;
    function setLotVariable() {
      if (!lot && event !== "delete") {
        setProduct(prevPost => {
          return {
            ...prevPost,
            lotId: nonLotTrackedLotId
          }
        });
        if (lotIdForNonLotTracked !== undefined) {
          setNonLotTrackedLotId(props.invItem.inventory[0].invLots[0]._id);
        } else {
          setNonLotTrackedLotId("");
        }
      }
    }
    setLotVariable();
    return () => {
      mounted = false;
    };
  }, []);


  function handleChange(event, index) {
    let { name, value } = event.target;
    //console.log("Name: ", name, " value: ", value);
    const now = new Date().toLocaleTimeString();
    //const date = new Date().toLocaleDateString();
    const date = new Date().toUTCString();

    let lotIndex = name.replace('lotAdjustment[', '');
    lotIndex = lotIndex.replace(']', '');
    //console.log("index is ", lotIndex);

    if (value === "" && name.includes("lotAdjustment")) {
      value = 0;
    }

    //IF Name of value being changed is for lotAdjustment & it is NOT a new lot. 
    if (name.includes("lotAdjustment") && lotIndex <= numberOfLots) {
      //console.log("lotindex is ", lotIndex, numberOfLots);
      let newlotAdjustments = lotAdjustment;
      //THIS IF STATEMENT IS TO CHECK IF IT IS NEW LOT TO NOT LOOK THROUGH ARRAY OF OLD LOTS
      if (parseInt(lotIndex) < numberOfLots) {
        newlotAdjustments[lotIndex] = {
          index: lotIndex,
          value: value,
          lot: invItem.lots[lotIndex].lot,
          location: invItem.lots[lotIndex].location,
          id: invItem.lots[lotIndex]._id
        }
        //console.log("new adjustment list is :", newlotAdjustments);
        handleNewInv(value);
      }
      //CASE FOR NEW LOT. Gets lot from invItem state instead of inventoried lots
      if (parseInt(lotIndex) === numberOfLots) {
        //console.log("inside new lot case. Lot: ", invItem.lot);
        setNewLot(prevPost => {
          return {
            ...prevPost,
            index: lotIndex,
            value: value
          }
        });
        //console.log("new adjustment list is :", newlotAdjustments);
      }
    }
    else if (name === "lot" || name === "location") {
      setNewLot(prevPost => {
        return {
          ...prevPost,
          [name]: value
        }
      });

      setProduct(prevPost => {
        return {
          ...prevPost,
          [name]: value
        };
      });

    }
    else if (invItem.event === "delete") {
      setDeleteVerifcation(prevPost => {
        return {
          ...prevPost,
          [name]: value
        };
      });
    }

    else {
      console.log("OK WERE INSIDE THE RIGHT ELSE: ", name, value);
      setProduct(prevPost => {
        return {
          ...prevPost,
          [name]: value
        };
      });
    }
    setTimeLog({
      time: now,
      date: date
    })

  }


  function handleClose() {
    setAddLocation(false);
  }

  function handleAddLocation(event) {
    event.preventDefault();
    setAddLocation(true);
  }

  function handleScannerOpen(event) {
    const { name } = event.target;
    //console.log("event is ", name, value);
    event.preventDefault();
    setScannerName(name);
    if (scanner === false) {
      setScanner(true);
    } else
      setScanner(false);
  }

  function handleBarcodeScan(input) {
    //event.preventDefault();
    setData("inside scan, ", input);
    //console.log("input is; ", input);
    //console.log("scanner name ", scannerName);
    setProduct(prevPost => {
      return {
        ...prevPost,
        [scannerName]: input
      };
    });
    setScanner(false);
  }



  function handleNewInv(value) {
    if (lot) {
      //console.log( numberOfLots, " Lot Adjustment is :", lotAdjustment, "invItem qty is: ", invItem.lots);
      let result = [];
      for (let i = 0; i <= numberOfLots + 1; i++) {
        if (i < numberOfLots) {
          if (isEmpty(lotAdjustment[i]) || isNaN(lotAdjustment[i] || parseFloat(lotAdjustment[i]) === 0)) {
            //console.log("isNan true", isNaN(lotAdjustment[i]), isEmpty(lotAdjustment[i]), " value: ", lot.qty);
            result.push(parseFloat(invItem.lots[i].qty));
          } else {
            //console.log("isNan false" , isNaN(lotAdjustment[i].value), " value: ", lotAdjustment[i].value);
            //console.log("i < numberOfLots");
            if (invItem.event === "add") {
              result.push(parseFloat(invItem.lots[i].qty) + parseFloat(lotAdjustment[i].value));
            }
            if (invItem.event === "remove" || invItem.event === "move") {
              result.push(parseFloat(invItem.lots[i].qty) - parseFloat(lotAdjustment[i].value));
            }
          }
        } else if (isEmpty(lotAdjustment[i]) || isNaN(lotAdjustment[i] || parseFloat(lotAdjustment[i]) === 0)) {
          //console.log("empty lot");
        } else {
          if (invItem.event === "add") {
            result.push(parseFloat(lotAdjustment[i].value));
          }
        }
      };

      var newTotalInv = result.reduce(function (a, b) {
        return a + b;
      }, 0);

      var newLotInv = invItem.lots.map((id, index) => {
        console.log("THE ID IN HERE IS: ", id._id);
        return {
          qty: result[index],
          lot: id.lot,
          location: id.location,
          id: id._id
        }
      })

      if (invItem.lot !== undefined && lotAdjustment[numberOfLots] !== undefined) {
        let lotSearch = newLotInv.find(element => element.lot === invItem.lot && element.location === invItem.location);
        //console.log("lot Search is ", lotSearch, invItem.location);
        if (lotSearch === undefined) {
          var newLotObject = {
            lot: invItem.lot,
            location: invItem.location,
            qty: lotAdjustment[numberOfLots].value
          }
          //console.log("HERE IS THE NEW LOT ", newLotObject);
          newLotInv.push(newLotObject);
        } else {
          let foundIndex = newLotInv.findIndex(element => element.lot === lotSearch.lot && element.location === invItem.location);
          //console.log("index is ",foundIndex, newLotInv);
          newLotInv[foundIndex].qty = parseFloat(newLotInv[foundIndex].qty) + parseFloat(lotAdjustment[numberOfLots].value);
        }

      }

      //console.log("Invitem Inventory is: ",invItem.inventory);
      //let UpdatedInv = invItem.inventory;
      //UpdatedInv[warehouseIndex]= result;

      //console.log("Invitem updated Inventory is: ",UpdatedInv);

      newLotInv = newLotInv.filter(newLotInv => newLotInv.qty > 0);
      //console.log("filterr lot Inv is ", newLotInv);
      setProduct(prevPost => {
        return {
          ...prevPost,
          newInv: newTotalInv,
          newLotInv: result,
          updatedLotInv: newLotInv
        };
      });

      //var newLotInv = for({lot: invItem.lots, qty:result};
      //console.log("new inv is ", newLotInv, "new Total inv is, ",newTotalInv, "invitem is: ", invItem);

      //console.log("inventory result is: ",result);
    }
    else {
      //console.log("in handle event false lot");
      const oldTotal = invItem.invTotal;
      let newInvValue = 0;
      if (invItem.event === "add") {
        console.log(value, " ", parseFloat(value));
        newInvValue = oldTotal + (parseFloat(value));
        console.log(newInvValue);
      }
      if (invItem.event === "remove") {
        newInvValue = oldTotal - (parseFloat(value));
      }
      setProduct(prevPost => {
        return {
          ...prevPost,
          newInv: newInvValue
        };
      });
    }
    console.log("item ", invItem, " adjustments: ", lotAdjustment);
  }



  function submit(submitEvent) {
    const userId = window.localStorage.getItem("userId");
    //CLEAR lotAdjustment SO NO EMPTY INDEXES ARE SENT
    let lotAdjustmentFiltered = lotAdjustment.filter(x => x !== null);

    //Variable to send. Filter is used on this to make sure newLot & Location is NOT a repeat
    let newLotData;
    newLotData = newLot;


    //event.preventDefault();
    //handleChange(event);
    //const { name, value } = event.target;
    console.log("EVENT ", event);

    let invAdjustment;
    let theValue;
    if (event === "add") {
      for (var i = 0; i < invItem.lots.length; i++) {
        console.log("adjustment: ", lotAdjustmentFiltered[i], " newlot: ", newLot);
        if (invItem.lots[i].lot === newLot.lot && invItem.lots[i].location === newLot.location) {
          console.log("INSIDE THE RARE CASE OF SAME LOT AND LOCATION", lotAdjustmentFiltered, " and ", lotAdjustmentFiltered[i]);
          if (lotAdjustmentFiltered[i] !== undefined) {
            theValue = parseInt(lotAdjustmentFiltered[i].value) + parseInt(newLot.value);
          } else {
            theValue = newLot.value;
          }
          lotAdjustmentFiltered[i] = {
            value: theValue,
            id: invItem.lots[i]._id
          }
          console.log("WHATS UP HERE? ", lotAdjustmentFiltered, "The value");
          /*
          lotAdjustmentFiltered.push({
            id:invItem.lots[i]._id,
            value: newLot.qty
          })
          */
          newLotData.value = 0;
        }
        console.log("THE LOT IS ADJUSTMENT ", lotAdjustmentFiltered);

      }
      invAdjustment = invItem.invAdjustment;
    }
    if (event === "remove") {
      lotAdjustmentFiltered = lotAdjustmentFiltered.map((item) => ({ ...item, value: item.value * -1 }))
      invAdjustment = invItem.invAdjustment * -1;
    }

    console.log("WTF IS THIS: ", lotAdjustmentFiltered);

    lotAdjustmentFiltered = lotAdjustmentFiltered.filter(x => x !== null);
    const payload = {
      invItem,
      eventTimeLog,
      lot,
      lotAdjustmentFiltered,
      userId,
      warehouseid,
      newLotData,
      invAdjustment
    };
    //console.log(lotAdjustment);
    //console.log("1 the event bool is ", props.fromWarehouseBool);
    axios({
      url: "/invEvent",
      method: "POST",
      data: payload
    })
      .then(() => {
        //console.log("data as been sent", invItem);
        //console.log("the event bool is ", props.fromWarehouseBool);
        if (props.fromWarehouseBool) {
          setToDash(true);
          window.location.reload(true);
          //console.log("from warehouse true case");
        }
        window.location.reload(true);

        //getProduct();
      })
      .catch(() => {
        console.log("server error");
      });
  };
  //console.log("event item is ", invItem);
  document.addEventListener('keydown', function (event) {
    //console.log("EVENT: ", event);
    if (event.key === 'Enter' && event.target.name === 'lot') {
      var form = event.target.form;
      let foundLot = invItem.lots.findIndex(lot => lot.lot === invItem.lot);
      //console.log("FOUND LOT: ", foundLot);
      var index = Array.prototype.indexOf.call(form, event.target);
      if (foundLot > -1) {    //LOT INDEX FOUND. JUMP TO CORRESPONDING LOT'S QTY FIELD.
        if (index === 1) {
          let newIndex = index + 2 + foundLot;
          //console.log("IN HERE ", newIndex);
          //form.elements[newIndex].focus();
          handleField(newIndex, form);
          return
        }
      } if (foundLot = -1) { //LOT INDEX NOT FOUND. NEW LOT, SO JUMP TO QTY.
        if (index === 2) {
          form.elements[index + 1].focus();
        }
      } else { //IMPOSSIBLE CASE
        console.log("HOW IS THIS?");
      }
      event.preventDefault();
    }
  });

  function handleField(index, form) {
    console.log("IN handleField ", index);
    form.elements[index].focus();
  }
  /*
    var inputs = (':input').keypress(function (e) {
      if (e.which == 13) {
        e.preventDefault();
        var nextInput = inputs.get(inputs.index(this) + 1);
        if (nextInput) {
          nextInput.focus();
        }
      }
    });
    */


  return (
    <>
      {//console.log("newLot: ", newLot)
      }
      {scanner ?
        <>
          {/*
          <BarcodeScannerComponent
            width={350}
            height={500}
            name={scannerName}
            value={scannerName}
            onUpdate={(err, result) => {
              if (result) {
                handleBarcodeScan(result.text)
              }
              else setData('Not Found')
            }
            }
          />
          */}
          <button className="locButton" onClick={handleScannerOpen}>Close Camera</button>
          <p>{data}</p>
        </>
        : null}

      {toDash ?
        <>
          <Switch>
            <Redirect to={("/warehouses", invItem.warehouse)} /> </Switch>
        </>
        : null}

      <>
        <Dialog open={addLocation} onClose={handleClose}>
          <DialogContent>
            <SingleLineForm
              props={props}
              placeholder={"Location"}
              checkboxcaption={false}
              checkBoxValue={false}
              name="newLocation"
              url="/addLocation" />
          </DialogContent>
        </Dialog>
      </>

      <form>
        <div>
          <h2> {invItem.event} {invItem.itemName} </h2>
          <div >

            {//FOR CAMERA SCANNING IN FUTURE! 
              //<button className="addIconButton" value="lot" name="lot" onClick={handleScannerOpen}>+</button>

            }
            {lot ?
              <>
                {invItem.event === "add" || invItem.event === "remove" ?
                  <>
                    {invItem.event === "add" ?
                      <>
                        {locations.list.length > 0 ? <>
                          <div id="container">
                            <select
                              className="locationSelect"
                              type="text"
                              name="location"
                              placeholder=""
                              onChange={handleChange}
                              value={invItem.location}>

                              <option placeholder="" value="">Location</option>
                              {locations.list.map((listItem, index) => {
                                return (
                                  <option key={index}>{listItem}</option>
                                )
                              })}
                            </select>

                            {//<button icon="add" className="addButton" onClick={handleAddLocation}>+</button>
                            }
                            {invItem.location}
                          </div> </> : null
                        }



                        <div id="container">
                          <input
                            className="smallInputField"
                            type="text"
                            name="lot"
                            placeholder="Lot"
                            value={invItem.lot}
                            autoComplete="off"
                            autoFocus
                            onChange={handleChange}
                          />


                        </div>

                        <input
                          type="number"
                          className="inputField"
                          name={"lotAdjustment[" + (invItem.lots.length) + "]"}
                          placeholder="Quantity"
                          autoComplete="off"
                          value={invItem.lotAdjustments[numberOfLots]}
                          //value ={lotAdjustment[(invItem.lots.length)]}
                          onChange={handleChange}
                        />
                      </>
                      : null}
                    {invItem.lots.length > 0 ? <>
                      <table>
                        <thead>
                          <tr>
                            <th>Lot Location</th>

                            <th>Inventory</th>
                            <th>{invItem.event}</th>
                            <th>New Inv</th>
                          </tr>
                        </thead>
                        <tbody>
                          {invItem.lots.map((lot, index) => {
                            return (
                              <tr key={index}>
                                <td className="tableCells">
                                  {lot.lot}  <br />
                                  {lot.location}
                                </td>

                                <td>
                                  {lot.qty}
                                </td>
                                <td>
                                  <input
                                    className="lotAdjustInput"
                                    min="0"
                                    type="number"
                                    name={"lotAdjustment[" + (index) + "]"}
                                    placeholder="Quantity"
                                    value={invItem.lotAdjustments[index]}
                                    onChange={handleChange}
                                  />
                                </td>
                                <td>
                                  <p>{invItem.newLotInv[index]}</p>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table> </>
                      : null}
                  </>
                  : null}
              </> :
              <>
                {invItem.event === "add" || invItem.event === "remove" ?
                  <>
                    <input
                      type="number"
                      name="invAdjustment"
                      placeholder={invItem.event === "add" ? "+" : "-"}
                      value={invItem.invAdjustment}
                      onChange={handleChange}
                      autoFocus
                    />
                    <p>
                      {invItem.event === "add" ? "to" : "from"} the current {invItem.invTotal}
                    </p>

                  </> :
                  null
                }
              </>
            }

            {invItem.event === "delete" ?
              <>
                <h2>
                  ARE YOU SURE YOU WANT TO DELETE? {invItem.itemName}
                  <br />
                  <br />
                  PLEASE VERIFY BY TYPING: <br /> "DELETE {invItem.itemName}"
                </h2>
                <input
                  value={userVerification.userVerification}
                  onChange={handleChange}
                  name="inputVerification" >
                </input>
                {userVerification.inputVerification === userVerification.deleteVerifcation ? <button onClick={submit}>Submit</button> : <>  {/*console.log(userVerification)*/} <p>Submit</p> </>}
              </>
              : null}
            {invItem.event !== "delete" ? <button className="" onClick={submit}>Submit</button> : null}
          </div>
        </div>
      </form>
    </>
  );

}

export default InvEventForm;