import React from 'react';

import DrawerToggleButton from "../SideDrawer/DrawerToggleButton";
import "./Guestbar.css";

const toolbar = props => (
    <header className="toolbar">
        <nav className="toolbar_navigation">
            <div className="toolbar_toggle-button">
                <DrawerToggleButton onClick={props.drawerClickHandler} />
            </div>
            <div className="toolbar_logo"><a href="/">EasyIn</a></div>
            <div className="spacer"/> 
            <div className="toolbar_navigation-items">
                <ul>
                    <li><a href="/">Home</a></li>
                </ul>
            </div>
            <a className="btn btn-light btn-md" href="/register" role="button">Register</a>
            <a className="btn btn-dark btn-md" href="/login" role="button">Login</a>
        </nav> 


        </header>
);

export default toolbar;