import React, {useState} from "react";
import axios from "axios";
import "./AddCustomerForm.css";

function AddCustomerForm(props){
  const organizationId = props.organizationId;
  let custId;

  if (props.customer !== undefined){
    custId = props.customer.customerId;
  } else {
    custId= "";
  }
  console.log(props);
  //const lotTrack = props.lot;
  const [customer, setCustomer] = useState({
    organizationId: organizationId,
    customerId: custId,
    event:props.customerEvent
  });

  //const [toDash, setToDash] = useState(false);

  //const [scanner, setScanner] = useState(false);
  //const [scannerName, setScannerName] = useState("");

  //const [data, setData ] = useState('Not Found');


  const [eventTimeLog, setTimeLog]= useState({
    time: "",
    date: ""
  })
/*
  useEffect(() => {
    let mounted = true;
    //console.log("1st mounted is ", mounted);
    return () =>{
      mounted = false;
    };
    }, [customer, props.props]);
*/

    /*
    function setLocations(event){
      console.log(event);
      setLocationList({locations: event});
      console.log(locationList);
    }*/

 function handleChange(event) {
  const { name, value } = event.target;

  const now = new Date().toLocaleTimeString();
  //const date = new Date().toLocaleDateString();
  const date = new Date().toUTCString();

  setCustomer(prevPost => {
    return {
      ...prevPost,
      [name]: value
    };
  });
  console.log("customer ", customer);
  setTimeLog({
    time: now,
    date: date
  })
}

function onKeyPress(event) {
  if (event.which === 13 /* Enter */) {
    event.preventDefault();
  }
}


function submit(event) {
  event.preventDefault();
  //const { name, value } = event.target;
  const payload = {
    customer,
    organizationId,
    eventTimeLog
  };
  
  axios({
    url: "/customerEvent",
    method: "POST",
    data: payload
  })
  .then(() => {
    console.log("data as been sent", payload);
    //setToDash(true);
    //console.log(toDash);
    window.location.reload(true);
    //getProduct();
  })
  .catch(() => {
    console.log("server error");
  });
};

  //console.log(invItem);

    return (
      <div className="addFormCustom">
      
      <div className=""> 
      <form onKeyPress={onKeyPress}>


      {props.customer !== undefined ? 
        null :    
      <>
      <h2>NEW CUSTOMER FORM</h2>

      <input 
        type ="text" 
        className ="smallInputField"
        name="accountName" 
        placeholder="Enter Account Name"
        value ={customer.accountName}
        onChange= {handleChange}
      />
      </>
      }

      <div className="">Location Info </div>
      
      <input 
        type ="text" 
        className ="smallInputField"
        name="locationName" 
        placeholder="Location Name"
        value ={customer.locationName}
        onChange= {handleChange}
      />
      
      <input 
        type ="text"
        className ="smallInputField"
        name="addressLineOne" 
        placeholder="Enter Address Line 1"
        value ={customer.addressLineOne}
        onChange= {handleChange}
      />



      <input 
        type ="text" 
        className ="smallInputField"
        name="addressLineTwo" 
        placeholder="Enter Address Line 2"
        value ={customer.addressLineTwo}
        onChange= {handleChange}
      />

      <input 
        type ="text" 
        className ="smallInputField"
        name="state" 
        placeholder="Enter State"
        value ={customer.state}
        onChange= {handleChange}
      />

      <input 
        type ="text" 
        className ="smallInputField"
        name="zipCode" 
        placeholder="Enter Zip Code"
        value ={customer.zipCode}
        onChange= {handleChange}
      />


      <button className="submitButton" onClick={submit}>Submit</button>
      </form>
      </div>
    </div>
    
    );
    
  }

export default AddCustomerForm;