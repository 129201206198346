import React, { useState } from "react";
import axios from "axios";
import "./UserForm.css";

import _ from "lodash";

function UserForm(props) {
  //ALL STATE VARIABLES DECALRED
  //console.log("THE PROPS " + props.fields[0].type);
  //const [orderProps, setEvent] = useState(props.event);

  let fieldResponseArray = [{
    column: []
  }];

  for (let fieldIndex = 0; fieldIndex < props.fields.length; fieldIndex++) {
    fieldResponseArray.push({
      column: []
    });
    //console.log("FIELD INDEX IS: ", fieldIndex);
    if (props.fields[fieldIndex].type === "table") {
      //console.log("fieldResponseArray IS: ", fieldResponseArray);
      for (let column = 0; column < props.fields[fieldIndex].columns.length; column++) {
        fieldResponseArray[fieldIndex].column.push({
          rows: []
        });
        for (let row = 0; row < props.fields[fieldIndex].rows.length; row++) {
          fieldResponseArray[fieldIndex].column[column].rows.push({
            row: row,
            value: ""
          });
          //console.log("field Index: ", fieldIndex, " Column: ", column, " Row: ", row);
        }
      }
    }
  }
  let now = new Date().toLocaleTimeString();
  //const date = new Date().toLocaleDateString();
  let date = new Date().toUTCString();


  //console.log("fields array: ", fieldResponseArray);

  //console.dir("props are: " + orderProps.orderList[0].productId);
  const [form, setForm] = useState({
    id: props.event.id,
    formName: props.event.formName,
    fields: props.fields,
    theCustomer: "",
    index: "",
    fieldResponses: fieldResponseArray,
    tableResponse: [""]
  });

  const organizationId = props.organizationId;

  const [eventTimeLog, setTimeLog] = useState({
    time: now,
    date: date
  })

  const [data, setData] = useState('Not Found');

  //Was considering using this variable for request address but for now will leave be... 
  //const [parameter, setParameter] = useState();

  /* //DONT REALLY NEED USEEFFECT. ALL INFO PASSED IN PROPS FROM TABLE ALREADY.
  useEffect(() => {
    let mounted = true;

    function getLocations() {
      axios.get("/" + orderProps.event + "/" + organizationId)
        .then((response) => {
          //const resdata = response.data;
          console.log("response data is ", response.data);
          if (mounted) {

          }

        })
        .catch(() => {
          alert("Error Retrieving data!");
        });
    }

    //getLocations();

    //NEED TO GET THIS TO MAP TO ORDER
    return () => {
      mounted = false;
    };

  }, []);
  */

  function handleChange(event) {
    event.preventDefault();
    const { name, value } = event.target;
    console.log("name: ", name, " value ", value);

    const now = new Date().toLocaleTimeString();
    //const date = new Date().toLocaleDateString();
    const date = new Date().toUTCString();


    if (name.includes("tableResponse")) {
      let parameters = name.substring(14);
      let [index, column, row] = parameters.split("~");
      let fieldResponses = form.fieldResponses;
      fieldResponses[index].column[parseInt(column)].rows[row] = {
        value: value
      }
      //console.log("parameters: ", parameters, " index: ", index, " column: ", column, " row: ", row);

      setForm(prevPost => {
        return {
          ...prevPost,
          fieldResponses: fieldResponses
        };
      });

    }
    else {
      /*
      setForm(prevPost => {
        return {
          ...prevPost,
          [name]: value
        };
      });
      */
    }

    setTimeLog({
      time: now,
      date: date
    })
  }

  function onKeyPress(event) {
    if (event.which === 13) { // Enter 
      event.preventDefault();
    }
  }

  function handleClose() {

  }

  function submit(event) {
    event.preventDefault();
    //const { name, value } = event.target;
    const payload = {
      organizationId,
      form,
      eventTimeLog
    };

    axios({
      url: "/postForm/" + form.id,
      method: "POST",
      data: payload
    })
      .then(() => {
        console.log("data as been snet0", payload);
        //setToDash(true);
        //console.log(toDash);
        window.location.reload(true);
        //getProduct();
      })
      .catch(() => {
        console.log("server error");
      });
  };

  //console.log(invItem);

  return (
    /* This doesnt work from dashboard but everything seems fine without... 
    { toDash ? <> <Switch> 
      <Redirect to={("/warehouses/", invItem.warehouse)} /> </Switch>  </>: 
      null}
      */
    <>
      {console.log("form is: ", form)}
      <div >

        <h2>{form.formName} FORM</h2>

        <form onKeyPress={onKeyPress}>

          <div id="" >
            <>
              {_.times(form.fields.length, i => (
                <>
                  <h3 className="fieldTitle">{form.fields[i].fieldTitle} </h3>

                  <div key={i} className="container scroll">
                    {form.fields[i].type === "table" ?
                      <>

                        <table className="fixed col-id-no">
                          <thead>
                            <tr>
                              <th> Row </th>
                            </tr>
                          </thead>
                          <tbody>
                            {_.times(form.fields[i].rows.length, x => (
                              <>
                                <tr >
                                  <td >{form.fields[i].rows[x]}
                                  </td>


                                </tr>
                              </>
                            ))}
                          </tbody>

                        </table>


                        <div className="">
                          <table className="scrollable">
                            <thead>
                              <tr>

                                {_.times(form.fields[i].columns.length, j => (
                                  <th> {form.fields[i].columns[j]}</th>

                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {_.times(form.fields[i].rows.length, x => (
                                <>
                                  <tr>
                                    {_.times(form.fields[i].columns.length, y => (
                                      <>
                                        <td>
                                          <input
                                            className="smaller-input"
                                            name={"tableResponse~" + i + "~" + y + "~" + x}
                                            value={form.fieldResponses[i].column[y].rows[x].value}
                                            onChange={handleChange}>
                                          </input>
                                        </td>
                                      </>
                                    ))}


                                  </tr>
                                </>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </>
                      : null
                    }

                  </div>

                  <br /> </>
              ))}
            </>

          </div>


          <div className="" >
            <button onClick={submit}>Submit</button>
          </div>


        </form>
      </div>
    </>
  );

}

export default UserForm;