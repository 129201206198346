import React from "react";

import "./SideDrawer.css";

const sideDrawer = props => {
    let drawerClasses = ["side-drawer"];
    if (props.show) {
        drawerClasses = ["side-drawer open"];
    }
    return (
        <nav className={drawerClasses}>
            <ul>
                <li><a href="/warehouses">Warehouses</a></li>
                <li><a href="/Customers">Customers</a></li>
                <li><a href="/Orders">Orders</a></li>
                <li><a href="/Departments">Departments</a></li>
                <li><a href="/settings">Settings</a></li>
                {//<li><a href="/workorders">Work Orders</a></li>
                }
            </ul>
        </nav>
    )
};

export default sideDrawer;