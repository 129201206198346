import React, { useState } from "react";
import axios from "axios";
import "./DeleteForm.css";

function DeleteForm(props) {
  console.log("PROPS FOR DELETE FORM ARE ", props);
  const itemName = props.objectName;
  const itemId = props.objectId;
  const delURL = props.delURL;
  const delMessage = props.delMessage;
  //const delVariable = props.apiDeleteVariable;

  const deleteVerifcation = "DELETE " + itemName;

  const [eventTimeLog, setTimeLog] = useState({
    time: "",
    date: ""
  })

  //console.log(deleteVerifcation);
  const [userVerification, setDeleteVerifcation] = useState({
    inputVerification: "",
    deleteVerifcation: deleteVerifcation
  })

  function handleChange(event, index) {
    let { name, value } = event.target;
    //console.log("Name: ", name, " value: ", value);
    const now = new Date().toLocaleTimeString();
    //const date = new Date().toLocaleDateString();
    const date = new Date().toUTCString();

    setDeleteVerifcation(prevPost => {
      return {
        ...prevPost,
        [name]: value
      };
    });

    setTimeLog({
      time: now,
      date: date
    })
  }

  function submit(event) {
    //event.preventDefault();
    handleChange(event);
    //const { name, value } = event.target;
    const payload = {
      itemId,
      eventTimeLog,
    };
    //console.log("1 the event bool is ", props.fromWarehouseBool);
    axios({
      url: delURL,
      method: "POST",
      data: payload
    })
      .then(() => {
        //console.log("data as been sent", invItem);
      })
      .catch(() => {
        console.log("server error");
      });
  };
  //console.log("event item is ", invItem);

  return (
    <>
      <div className="form-input">
        <form>
          <>
            <h2>
              ARE YOU SURE YOU WANT TO DELETE {itemName} ?
              <br />
              <br />
              {delMessage !== null ?
                <>
                  {delMessage}
                  <br />
                  <br />
                </> :
                null
              }
              PLEASE VERIFY BY TYPING: <br /> "{deleteVerifcation}"
            </h2>
            <input
              value={userVerification.userVerification}
              onChange={handleChange}
              name="inputVerification" >
            </input>
            {userVerification.inputVerification === userVerification.deleteVerifcation ? <button onClick={submit}>Submit</button> : <>  {/*console.log(userVerification)*/} <p>Submit</p> </>}
          </>
        </form>
      </div>
    </>
  );
}

export default DeleteForm;