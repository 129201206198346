import React, {useState, useEffect} from "react";
import axios from "axios";

import MaterialTable from '@material-table/core';
import Add from '@material-ui/icons/Add';
import Delete from '@material-ui/icons/Delete';
//import Subtract from '@material-ui/icons/Remove';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import AddCustomerForm from "../../AddCustomerForm/AddCustomerForm"
import DeleteForm from "../../DeleteForm/DeleteForm"

import SingleLineForm from "../../SingleLineForm/SingleLineForm";

import "./DepartmentsPage.css";

//import { set } from "lodash";

function DepartmentsPage(props){
  const organizationId= props.id;

  const [list, setList] = useState({
    departments:[]
  })

  const [departmentEvent, setDepartmentEvent] = useState("");

  useEffect(() => {
    let mounted = true;
    function getProduct () {
      axios.get("/departments/" + organizationId)
          .then((response) => {
            const data = response.data;
            
            if (mounted){
              setList({departments:data.foundItems.departments});
              //setList({warehouses: data});
              //console.log("invitemList data received: ", data);
              //console.log("list is ", data.foundItems);
            }
          })
          .catch(() => {
            alert("Error Retrieving data!");
          });
        }
        getProduct();
        return () =>{
          mounted = false;
        };
      }, []);

      const columns = [
        {
          title: "Departments Name",
          field: "departmentName",
          render: row => (<div> {row.departmentName} </div>)
          //render: row => (<div> <a href={"/customers/"+row._id}> {row.accountName} </a> </div>)
        }
        /*,
        {
          title: "Inventory2",
          field: "inventory[0].invTotal",
          customFilterAndSearch: "",
        }*/
      ]

      const handleClose = () => {
        setDepartmentEvent({
          bool:false,
          event: ""});
      };

    return(
    <div>
        <>
        {console.log("list set to: ", list)}
        {departmentEvent ? 
        <>
        <Dialog open={departmentEvent.bool} onClose={handleClose}>
          <DialogContent>
            { 
            departmentEvent.event ==="new" ?
            <SingleLineForm
            placeholder={"New Department"}
            url={"/newDepartment"}
            name={"newDepartment"}
            organizationId={organizationId}
            checkboxcaption={false}
          >
          </SingleLineForm>  : 
              null
            }
            { departmentEvent.event ==="delete" ?
            <DeleteForm
              objectName ={departmentEvent.departmentName}
              objectId={departmentEvent.departmentId}
              delURL={departmentEvent.delUrl}
              delMessage = {"Deleting customer accounts will delete ALL invoices associated as well. To proceed"}
            /> 
            : null 
            }


          </DialogContent>
        </Dialog>

        </> : 
        null }
      </>

      <h2 className="pageTitle">Departments</h2>

    <br/>
     
    <MaterialTable
          className="Waretable"
          title="Departments"
          data= {list.departments}
          columns={columns}
          /*
          editable={{
            onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                const dataUpdate = [...data];
                const index = oldData.tableData.id;
                dataUpdate[index] = newData;
                setData([...dataUpdate]);
  
                resolve();
              }, 1000)
            })
          }}*/
          actions={[
            {
              icon: Add,
              tooltip: 'New Department',
              isFreeAction: true,
              onClick: (event) => { 
                setDepartmentEvent({
                  bool: true,
                  event: "new"
                });
                //console.log(customerEvent);
              }
            },
            {
              icon: Delete,
              tooltip: 'Delete Account',
              onClick: (event, rowData) => {
                setDepartmentEvent({
                  bool: true,
                  event: "delete",
                  departmentName: rowData.departmentName,
                  delUrl: "/deleteDepartment/"+rowData._id,
                  departmentId: rowData._id,
                });
              }
            }
          ]}
          options={{
            search: true , 
            titleStyle:{
              fontWeight: 'bold',
              fontSize: '4rem'
            },
            headerStyle:{backgroundColor:'',
              fontWeight: 'bold',
              fontSize: '2rem'}, 
            rowStyle: {
                backgroundColor: '#EEE',
                fontSize:'2rem'
              },
            title: {backgroundColor:'#EEE'}, 
            paging: false, 
            filtering: false, 
            exportButton: true, 
            actionsColumnIndex: -1}}
          detailPanel={[
            {
              tooltip: 'Show Details',
              render: rowData => {
                return (
                  <div
                    style={{  
                      backgroundColor: '#EEE',
                      fontSize:'2rem',
                      padding: "2rem"
                    }}
                  >
                    <div> 
                      {console.log("rowdata: ", rowData)}
                    <a href={"/departmentForms/" + rowData.rowData._id}>Forms</a>
                    </div>
                    </div>
                )
              },
            },
          ]}
    />
    </div>
    );
}

export default DepartmentsPage;