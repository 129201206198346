import React, { useState, useEffect } from "react";
import axios from "axios";

//Components 
import Guestbar from "./Guestbar/Guestbar";
import Toolbar from "./Toolbar/Toolbar";
import SideDrawer from "./SideDrawer/SideDrawer";
import GuestSideDrawer from "./SideDrawer/GuestSideDrawer";
import Backdrop from "./Backdrop/Backdrop";
import Footer from "./Footer/Footer";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom"

//PAGES
import HomePage from "./pages/HomePage/HomePage";
import RegisterPage from "./pages/RegisterPage/RegisterPage";
import LoginPage from "./pages/LoginPage/LoginPage";
import DashboardPage from "./pages/DashboardPage/DashboardPage";
import WarehousesPage from "./pages/WarehousesPage/WarehousesPage";
import WarehousePage from "./pages/WarehousePage/WarehousePage";
import CustomersPage from "./pages/CustomersPage/CustomersPage";
import CustomerPage from "./pages/CustomerPage/CustomerPage";
import OrdersPage from "./pages/OrdersPage/OrdersPage";
import OrderPage from "./pages/OrderPage/OrderPage";
import DepartmentsPage from "./pages/DepartmentsPage/DepartmentsPage";
import FormsPage from "./pages/UserFormsPage/UserFormsPage";
import PostedUserFormPage from "./pages/PostedUserFormPage/PostedUserFormPage";

//Future project
//import WorkOrdersPage from "./pages/WorkOrdersPage/WorkOrdersPage";
//import WorkOrderPage from "./pages/WorkOrderPage/WorkOrderPage";

import SettingsPage from "./pages/SettingsPage/SettingsPage";
import NotFoundPage from "./pages/404";


import '../index.css';

let backdrop;

function App() {

  const [theme, setTheme] = useState("light");

  const themeToggler = () => {
    theme === "light" ? setTheme("dark") : setTheme("light");
  };

  //NAV BAR HANDLES///////////////////////////////////
  const [isRegistered, setRegistered] = useState("false");
  const [headingText, setHeading] = useState("");
  const [isOrganization, setOrganization] = useState("");

  /*
  const [user, setUser] = userState({
  })*/

  useEffect(() => {
    const isAuthenticated = window.localStorage.getItem("isAuthenticated");
    setRegistered(isAuthenticated);
    const theOrganization = window.localStorage.getItem("organization");
    setOrganization(theOrganization);
    //console.log(theBakery);
    if (isAuthenticated) {
      axios.get("/organization/" + theOrganization, {
      })
        .then((response) => {
          const data = response.data;
          //console.log("received data is ", data);
          setHeading(data.name);
        })
        .catch(() => {
          alert("Error Retrieving data!");
        });
    }
  }, [headingText, isOrganization]);
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);

  //OPEN SIDE DRAWER
  function drawerToggleClickHandler() {
    setSideDrawerOpen(() => {
      return { sideDrawerOpen: true };
    });
  };

  //CLOSE SIDE DRAWER
  function backdropClickHandler() {
    setSideDrawerOpen(false);
  }

  //CONDITION FOR NAV BAR
  if (sideDrawerOpen) {
    backdrop = <Backdrop onClick={backdropClickHandler} />
  }

  ///CHILD PAGES/////////////////////
  const ChildDashboardPage = ({ match }) => (
    <DashboardPage
      id={isOrganization}
      name={headingText}
    />
  )

  const ChildWarehousePage = ({ match }) => (
    <WarehousePage
      id={match.params.warehousesId}
      organizationId={isOrganization} />
  )

  const ChildWarehousesPage = ({ match }) => (
    <WarehousesPage
      id={isOrganization} />
  )

  const ChildCustomersPage = ({ match }) => (
    <CustomersPage
      id={isOrganization} />
  )

  const ChildCustomerPage = ({ match }) => (
    <CustomerPage
      customerId={match.params.customersId}
      organizationId={isOrganization} />
  )

  const ChildOrdersPage = ({ match }) => (
    <OrdersPage
      customerId={match.params.customersId}
      id={isOrganization} />
  )

  const ChildOrderPage = ({ match }) => (
    <OrderPage
      orderId={match.params.orderId}
      id={isOrganization} />
  )

  const ChildDepartmentsPage = ({ match }) => (
    <DepartmentsPage
      id={isOrganization} />
  )

  const ChildFormsPage = ({ match }) => (
    <FormsPage
      departmentId={match.params.departmentId}
      id={isOrganization} />
  )

  const ChildPostedUserFormPagePage = ({ match }) => (
    <PostedUserFormPage
      formId={match.params.formId}
      historyId={match.params.historyId}
      id={isOrganization} />
  )


  /*
  const ChildWorkOrdersPage = ({ match }) => (
    <WorkOrdersPage
    id={isOrganization} />
  )

  const ChildWorkOrderPage = ({ match }) => (
    <WorkOrderPage
      id={match.params.workOrderId} 
      organizationId={isOrganization}/>
  )
*/

  const ChildSettingsPage = ({ match }) => (
    <SettingsPage
      id={isOrganization}
      name={headingText} />
  )

  return (
    <div data-theme={theme}
      className="app"
    //style={{ height: "100%" }}
    >
      {//<link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons"></link>
      }
      {sideDrawerOpen ? backdrop : null}

      {//console.log("isRegistered status is: ", isRegistered)
      }
      {isRegistered ?
        <>
          <div> <Toolbar drawerClickHandler={drawerToggleClickHandler} />
            <SideDrawer show={sideDrawerOpen} /> </div>

          <Router>
            <Switch>
              <Route exact path="/" component={ChildDashboardPage} />
              <Route exact path="/login" component={ChildDashboardPage} />
              <Route exact path="/signin" component={ChildDashboardPage} />
              <Route exact path="/register" component={ChildDashboardPage} />
              <Route exact path="/dashboard" component={ChildDashboardPage} />
              <Route exact path="/home" component={ChildDashboardPage} />
              <Route exact path="/settings" component={ChildSettingsPage} />

              <Route exact path="/warehouses" component={ChildWarehousesPage} />
              <Route exact path="/warehouses/:warehousesId" component={ChildWarehousePage} />

              <Route exact path="/customers" component={ChildCustomersPage} />
              <Route exact path="/customers/:customersId" component={ChildCustomerPage} />

              <Route exact path="/orders" component={ChildOrdersPage} />
              <Route exact path="/order/:orderId" component={ChildOrderPage} />
              
              <Route exact path="/departments" component={ChildDepartmentsPage} />
              <Route exact path="/departmentForms/:departmentId" component={ChildFormsPage} />
              <Route exact path="/postedForm/:historyId" component={ChildPostedUserFormPagePage} />
              {//<Route exact path="/workorders" component={ChildWorkOrdersPage} />
                //<Route exact path="/workorders/:workOrderId" component={ChildWorkOrderPage} />
              }
              <Route exact path="/signout" component={HomePage} />

              <Route path="/ingredients/:ingredientId" component={""} />
              <Route path="/404" component={NotFoundPage} />
              <Redirect to="/404" />
            </Switch>
          </Router>
        </>
        :
        <>
          <Guestbar drawerClickHandler={drawerToggleClickHandler} />
          <GuestSideDrawer show={sideDrawerOpen} />
          <Router>
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route exact path="/signout" component={HomePage} />
              <Route exact path="/signin" component={HomePage} />
              <Route exact path="/home" component={HomePage} />
              <Route exact path="/dashboard" component={HomePage} />
              <Route exact path="/login" component={LoginPage} />
              <Route exact path="/register" component={RegisterPage} />
              <Route path="/404" component={NotFoundPage} />
              <Redirect to="/404" />
            </Switch>
          </Router>
        </>
      }

      <Footer />

    </div>

  );
}
//<LoginForm isRegister={userIsRegistered} />
export default App;
