import React from "react";
import LoginForm from "../../LoginForm/LoginForm";

function LoginPage(){

    return(<div>

    <h2 className="pageTitle">Login</h2>
      <LoginForm 
        type="login"
        isRegistered 
        />

      </div>
    );

}

export default LoginPage;

