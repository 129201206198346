import React, { useState, useEffect } from "react";
import axios from "axios";
import "./CommitOrderForm.css";

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import DeleteIcon from '@material-ui/icons/Delete';
//import { ListItem } from "@material-ui/core";

/*
import { BrowserRouter as Switch, Redirect } from "react-router-dom"

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import SingleLineForm from "../SingleLineForm/SingleLineForm"
import { isEmpty, isNaN } from "lodash"; //can import isUndefined if needed as well...

import BarcodeScannerComponent from "react-webcam-barcode-scanner";
*/

function InvEventForm(props) {
  let orderId = props.event.orderId;

  const [orderProps] = useState(props.event);

  const [order, setOrder] = useState({});

  const [productList, setProductList] = useState({});

  const [adjustment, setAdjustment] = useState([]);

  const [toCommit, setToCommit] = useState([]);

  const [warehouseId, setWarehouseId] = useState([]);

  const [eventTimeLog, setTimeLog] = useState({
    time: "",
    date: ""
  });

  const [quickScanValues, setQuickScanValues] = useState({
    open: false,
    scanStep: 0,
    productIndex: null,
    itemIndexInOrder: null,
    GTIN: "",
    max: undefined,
    lot: "",
    lotName: "",
    qty: "",
    toCommit: true,
    name: ""
  });

  const [scanner, setScanner] = useState(false);
  const [product, setProduct] = useState({});
  const [scannerName, setScannerName] = useState("");
  const [data, setData] = useState('Not Found');

  useEffect(() => {
    let mounted = true;
    function getOrder() {
      axios.get("/" + orderProps.event + "/" + orderId)
        .then((response) => {
          //console.log("response data is ", response.data);
          if (mounted) {
            setOrder(response.data.order);
            setProductList(response.data.items);
            response.data.order.orderList.forEach((product, index) => {
              setWarehouseId(response.data.order.warehouseId);

              //console.log("product: ", product, " index for items: ", response.data.items.findIndex((item) => item._id === product.productId));
              setAdjustment(prevPost => {
                return {
                  ...prevPost,
                  [index]: {
                    productId: response.data.items[response.data.items.findIndex((item) => item._id === product.productId)]._id,
                    lot: response.data.items[response.data.items.findIndex((item) => item._id === product.productId)].inventory[0].invLots[0]._id,
                    max: response.data.items[response.data.items.findIndex((item) => item._id === product.productId)].inventory[0].invLots[0].qty,
                    qty: 0
                  }
                }
              })
            })
          }
        })
        .catch(() => {
          alert("Error Retrieving data!");
        });
    }

    getOrder();

    //NEED TO GET THIS TO MAP TO ORDER
    return () => {
      mounted = false;
    };

  }, [toCommit]);

  function handleSelect(event, index) {
    let { name, value } = event.target;
    let qty = adjustment[name].qty
    setAdjustment(prevPost => {
      return {
        ...prevPost,
        [name]: {
          productId: adjustment[name].productId,
          lot: value,
          //max: order[index].qty,
          qty: qty
        }
      }
    })
  }

  function handleChange(event, index) {
    let { name, value } = event.target;
    //console.log("Name: ", name, " value: ", value);

    if (name === "GTIN" || name === "lot" || name === "qty") {
      setQuickScanValues(prevPost => {
        return {
          ...prevPost,
          [name]: value
        }
      })
      handleScan(event);
    } else {
      let theIndex = name.slice(3);
      let lot = adjustment[theIndex].lot;
      setAdjustment(prevPost => {
        return {
          ...prevPost,
          [theIndex]: {
            qty: value,
            lot: lot,
            productId: adjustment[theIndex].productId
          }
        }
      })
    }
    const now = new Date().toLocaleTimeString();
    const date = new Date().toUTCString();
    setTimeLog({
      time: now,
      date: date
    })
  }

  function handleCommitEvents(event) {
    let { name, value } = event.target;
    console.log("INSIDE REGULAR HANDLEADD: ", value, " name ", name);
    //console.log("COMMIT: ", toCommit, " length: ", toCommit.length);
    let updatedCommit = toCommit;
    //console.log("WHATS THIS: ", updatedCommit.includes(commit => commit.lot = [adjustment[value].lot]), " TO COMMIT: ",updatedCommit, " LOT TO ADD: ", adjustment[value].lot );
    //console.log("LETS SEE ", updatedCommit.filter(commit => commit.lot === adjustment[value].lot))
    if (name === "add") {
      if (updatedCommit.filter(commit => commit.lot === adjustment[value].lot).length > 0) {
        console.log("INSIDE HANDLADD, COMMIT DOES INCLUDE LOT");
        let commitIndex = updatedCommit.findIndex(commit => commit.lot === adjustment[value].lot);
        updatedCommit[commitIndex].qty = parseInt(updatedCommit[updatedCommit.findIndex(commit => commit.lot === adjustment[value].lot)].qty) + parseInt(adjustment[value].qty);
      } else {
        console.log("INSIDE HANDLADD, COMMIT DOES NOT INCLUDE LOT");
        updatedCommit.push({
          index: parseInt(value),
          lot: adjustment[value].lot,
          lotName: productList[productList.findIndex((product) => product._id === adjustment[value].productId)].inventory[0].invLots[productList[productList.findIndex((product) => product._id === adjustment[value].productId)].inventory[0].invLots.findIndex((lot) => lot._id === adjustment[value].lot)].lot,
          qty: adjustment[value].qty,
          productId: adjustment[value].productId
        })
      }
    } else if (name ==="delete"){
      updatedCommit.splice(value, 1);

    }
    setToCommit(updatedCommit);
    console.log("THE COMMIT IS GOING TO BE: ", updatedCommit);

    const now = new Date().toLocaleTimeString();
    const date = new Date().toUTCString();
    setTimeLog({
      time: now,
      date: date
    })
  }

  //HANDLE CONDITIONS TO SHOW SCANNER DIALOG WINDOW & INPUT FIELD
  function scanOpenClose() {
    if (quickScanValues.open) {
      setQuickScanValues({
        open: false,
        scanStep: 0,
        productIndex: null,
        itemIndexInOrder: null,
        GTIN: "",
        max: undefined,
        lot: "",
        lotName: "",
        qty: undefined,
        toCommit: true,
        name: ""
      })
    } else {
      setQuickScanValues(prevPost => {
        return {
          ...prevPost,
          open: true
        }
      })
    }
  }

  //PROCESS SCAN INPUTS
  function handleScan(event) {
    let { name, value } = event.target;
    let step = quickScanValues.scanStep;

    if (step === 0) {
      //console.log("ORDERLIST: ", order.orderList);
      let productIndex = productList.findIndex(product => product.sku === value);
      if (productIndex > -1) {

        let itemIndexInOrder = order.orderList.findIndex(product => product.productId === productList[productIndex]._id);
        let nextStep = step + 1;
        //HANDLECHANGE QUICK SCAN VARIABLES 
        setQuickScanValues(prevPost => {
          return {
            ...prevPost,
            productIndex: productIndex,
            itemIndexInOrder: itemIndexInOrder,
            GTIN: productList[productIndex].sku,
            productId: productList[productIndex]._id,
            name: productList[productIndex].itemName,
            scanStep: nextStep
          };
        });
      }
    }
    if (step === 1) {
      let lotIndex = productList[quickScanValues.productIndex].inventory[0].invLots.findIndex(lot => lot.lot === value);
      if (lotIndex > -1) {
        let nextStep = step + 1;
        //HANDLECHANGE QUICK SCAN VARIABLES 
        setQuickScanValues(prevPost => {
          return {
            ...prevPost,
            lotIndex: lotIndex,
            lot: productList[quickScanValues.productIndex].inventory[0].invLots[lotIndex]._id,
            lotName: productList[quickScanValues.productIndex].inventory[0].invLots[lotIndex].lot,
            scanStep: nextStep
          };
        });
      }
    }
    if (step === 2) {
      let lotIndex = productList[quickScanValues.productIndex].inventory[0].invLots.findIndex(lot => lot.lot === value);
      if (lotIndex > -1) {
        let nextStep = step + 1;
        //HANDLECHANGE QUICK SCAN VARIABLES 
        setQuickScanValues(prevPost => {
          return {
            ...prevPost,
            qty: quickScanValues.qty,
            scanStep: nextStep
          };
        });
      }
    }
  }

  function handleScanAdd(event) {
    event.preventDefault();
    if (quickScanValues.qty > 0) {
      console.log("INSIDE HANDLESCANADD: ", "qty is : ", quickScanValues.qty);
      //console.log("COMMIT: ", toCommit, " length: ", toCommit.length);
      let updatedCommit = toCommit;
      //console.log("WHATS THIS: ", updatedCommit.includes(commit => commit.lot = [adjustment[value].lot]), " TO COMMIT: ",updatedCommit, " LOT TO ADD: ", adjustment[value].lot );

      if (updatedCommit.filter(commit => commit.lot === quickScanValues.lot).length > 0) {
        console.log("INSIDE HANDLESCANADD COMMIT DOES INCLUDE LOT");
        let commitIndex = updatedCommit.findIndex(commit => commit.lot === quickScanValues.lot);
        updatedCommit[commitIndex].qty = parseInt(updatedCommit[updatedCommit.findIndex(commit => commit.lot === quickScanValues.lot)].qty) + parseInt(quickScanValues.qty);
      } else {
        console.log("INSIDE HANDLESCANADD COMMIT DOES NOT INCLUDE LOT");
        updatedCommit.push({
          index: quickScanValues.itemIndexInOrder,
          lot: quickScanValues.lot,
          lotName: quickScanValues.lotName,
          productId: quickScanValues.productId,
          qty: quickScanValues.qty
        })
      }
      //setToCommit(updatedCommit);
      console.log("THE COMMIT IS GOING TO BE: ", updatedCommit);
      setQuickScanValues(prevPost => {
        return {
          ...prevPost,
          toCommit: false
        }
      });

      const now = new Date().toLocaleTimeString();
      const date = new Date().toUTCString();

      setTimeLog({
        time: now,
        date: date
      })

      setQuickScanValues({
        open: true,
        scanStep: 0,
        productIndex: null,
        itemIndexInOrder: null,
        GTIN: "",
        max: undefined,
        lot: "",
        lotName: "",
        qty: "",
        toCommit: true,
        name: ""
      });
    }

  }

  document.addEventListener('keydown', function (event) {
    //console.log("QUICKSCAN STEP IS: ", quickScanValues.scanStep );
    if (event.key === 'Enter' && event.target.name === 'qty' && quickScanValues.scanStep === 2) {
      //console.log("ENTER EVENT: ", event.name, " ", event);
      //handleScanAdd();
      //event.preventDefault();
    }
  });

  function submit(event) {
    const now = new Date().toLocaleTimeString();
    //const date = new Date().toLocaleDateString();
    const date = new Date().toUTCString();

    setTimeLog({
      time: now,
      date: date
    })
    //console.log("Inside submit");
    const userId = window.localStorage.getItem("userId");
    //CLEAR lotAdjustment SO NO EMPTY INDEXES ARE SENT
    const payload = {
      user: userId,
      warehouseId: warehouseId,
      commits: toCommit,
      eventLog: {
        time: now,
        date: date
      }
    };
    //console.log("1 the event bool is ", props.fromWarehouseBool);
    axios({
      url: ("/commitOrder/" + orderId),
      method: "POST",
      data: payload
    })
      .then((response) => {
        console.log("data as been sent", payload);
        window.location.reload(true);
      })
      .catch(() => {
        console.log("server error");
      });
  };


  //console.log("event item is ", invItem);
  let commitTotal = 0;
  return (
    <>
      {console.log("QUICK SCAN VALUES: ", quickScanValues)
      }
      <h1>Commit Order Form</h1>
      <button onClick={scanOpenClose}>
        Scanner
      </button>
      <Dialog open={quickScanValues.open} onClose={scanOpenClose}>
        <DialogContent>
          {quickScanValues.scanStep === 0 ?
            <>          Scan product GTIN {" "}
              <input autoFocus name="GTIN" value={quickScanValues.GTIN} type="text" placeholder="GTIN" onChange={handleChange} />

            </>
            :
            <> {quickScanValues.scanStep === 1 ?
              <>
                Product: {quickScanValues.name} <br />
                Scan Lot To Commit <br />
                <input autoFocus name="lot" value={quickScanValues.lot} type="text" placeholder="Lot" onChange={handleChange} />

              </> : <> {quickScanValues.scanStep === 2 ?
                <>
                  Product: {quickScanValues.name} <br />
                  Lot: {quickScanValues.lotName} <br />
                  Quantity to commit: <br />
                  <form>
                    <input autoFocus name="qty" value={quickScanValues.qty} type="number" placeholder="Quantity" onChange={handleChange} />
                    <button onClick={handleScanAdd}>+</button>
                  </form>

                </> : null}
              </>}
            </>
          }
        </DialogContent>
      </Dialog>
      {//console.log("The order is: ", order)
      }
      {//console.log("Adjustment is : ", adjustment)
      }
      {//console.log("toCommit is : ", toCommit, " with length: ", toCommit.length)
      }
      {order.orderList !== undefined && productList.length > 0 ?
        <>
          <div>
            <table>
              <tbody>
                <tr>
                  <th className="lotTable"> Product | In. </th>
                  <th className="lotTable"> Qty </th>
                  <th className="lotTable"> Lot  </th>
                  <th className="lotTable"> Quantity </th>
                </tr>

                {order.orderList.map((item, index) => {
                  {
                    commitTotal = 0
                    toCommit.forEach(function (commit) {
                      if (parseInt(commit.index) === index) {
                        commitTotal = commitTotal + parseInt(commit.qty)
                      }
                    })
                  }

                  if (commitTotal < item.qty) {
                    return (
                      <tr key={index} className="lotTable">
                        <td>
                          {productList[productList.findIndex((product) => product._id === item.productId)].itemName}
                          {" "}
                          {productList[productList.findIndex((product) => product._id === item.productId)].invTotal}
                        </td>
                        <td>
                          {item.qty}
                        </td>
                        <td>
                          <select
                            onChange={handleSelect}
                            name={index}
                            key={index}>
                            {productList[productList.findIndex((product) => product._id === item.productId)].inventory[0].invLots.map((lot, index2) => {
                              return (<>

                                <option value={lot._id} >
                                  {lot.lot}
                                  {" "}
                                  {lot.qty}

                                </option>
                              </>
                              )
                            })}
                            {//console.log("adjustments: ", adjustment, "length: ", adjustment.length)
                            }
                          </select>
                        </td>
                        {true ? <>
                          <td>
                            <input
                              type="number"
                              placeholder="qty"
                              name={"qty" + index}
                              //type="number"
                              max={item.qty}
                              min="0"
                              //value={adjustment[index].qty}

                              onChange={handleChange}
                            />
                            <button
                              onClick={handleCommitEvents}
                              value={index}
                              name={"add"}
                            >
                              +
                            </button>
                          </td>
                        </> : null}
                      </tr>

                    )
                  }
                })}
              </tbody>
            </table>
          </div>


        </> : "false"}
      {//console.log("To commit: ", toCommit)
      }

      {toCommit.length > 0 ?
        <>

          {toCommit.map((toCommitRow, index) => {
            return (<>
              <div key={index}>
                {productList[productList.findIndex((product) => product._id === toCommitRow.productId)].itemName + " "}
                {productList[productList.findIndex((product) => product._id === toCommitRow.productId)].inventory[0].invLots[productList[productList.findIndex((product) => product._id === toCommitRow.productId)].inventory[0].invLots.findIndex((lot) => lot._id === toCommitRow.lot)].lot + " "}
                {toCommitRow.qty + " "}

                <button
                  value={index}
                  name={"delete"}
                  onClick={handleCommitEvents} >
                  X
                </button>

                {//console.log("to Commit row mapping", toCommitRow)
                }
                <br />

              </div>
            </>
            )
          })}

          <button
            onClick={submit}>
            Submit
          </button>
        </> : null
      }
    </>
  );

}

export default InvEventForm;