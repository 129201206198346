import React from "react";

const NotFoundPage = () => {
return (
<div>
    <h3>404 Error. Not Found</h3>
    <small>Sorry</small>
</div>
);

};
export default NotFoundPage;